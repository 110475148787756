import {NavLink} from "react-router-dom";
import React, {useState} from "react";
import style from "./Nav.module.scss"

const navItemStyle = ({isActive}) => `${style.navItem} ${isActive && style.activeNavItem}`

export default function () {

    const [collapsed, setCollapsed] = useState(false);

    return (
        <div className={style.container}>
            <div className={`${style.ball} ${collapsed && style.ballCollapsed}`}
                 onClick={() => setCollapsed(!collapsed)}>
                <span className="material-symbols-outlined">chevron_left</span>
            </div>
            <div className={`${style.content} ${collapsed && style.contentCollapsed}`}>
                <h1>Parpan</h1>
                <div className={`${style.menu} ${collapsed && style.menuCollapsed}`}>
                    <nav className={`${style.nav}`}>
                        <NavLink to={"/overview"} className={navItemStyle}>
                            <span className="material-symbols-outlined">team_dashboard</span>
                            <p>Overview</p>
                        </NavLink>
                        <NavLink to={"/dashboard"} className={navItemStyle}>
                            <span className="material-symbols-outlined">bar_chart</span>
                            <p>Dashboard</p>
                        </NavLink>
                        <NavLink to={"/transactions"} className={navItemStyle}>
                            <span className="material-symbols-outlined">table_rows_narrow</span>
                            <p>Transactions</p>
                        </NavLink>
                        <NavLink to={"/synthesise"} className={navItemStyle}>
                            <span className="material-symbols-outlined">science</span>
                            <p>Synthesise</p>
                        </NavLink>
                        <NavLink to={"/tags"} className={navItemStyle}>
                            <span className="material-symbols-outlined">label</span>
                            <p>Tags</p>
                        </NavLink>
                        <NavLink to={"/pools"} className={navItemStyle}>
                            <span className="material-symbols-outlined">water_full</span>
                            <p>Pools</p>
                        </NavLink>

                        <NavLink to={"/settings"} className={navItemStyle}>
                            <span className="material-symbols-outlined">tune</span>
                            <p>Settings</p>
                        </NavLink>
                    </nav>
                    <div className={`${style.footer}`}>
                        <a target="_blank" href="https://git.g-7.io/joeysciamanna/parpan">v{process.env.VERSION}</a>
                    </div>
                </div>
            </div>
        </div>
    )
}
