@import "../index";

.cell {
  background-color: $secondary-color;
  box-shadow: rgba(0, 0, 0, 0.28) 0 0 5px;
}

.dragHandle {
  display: block;
  cursor: move;
}

.resizeHandle {
  bottom: 0;
  right: 0;
  cursor: se-resize;
  position: absolute;
  width: 20px;
  height: 20px;
}

.resizeHandle::after {
  content: "";
  border-bottom: 2px solid $accent-color;
  border-right: 2px solid $accent-color;
  width: 5px;
  height: 5px;
  position: absolute;
  bottom: 3px;
  right: 3px;
}

.notResizable .resizeHandle {
  display: none;
}

.notDraggable .dragHandle {
  display: none;
}

