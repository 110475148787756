@import "../../index";

.sectionHead {
  @extend .sectionHead;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
}

.dragHandle {

}

.cell {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.cellContent {
  height: 100%;
  overflow: scroll;
}

.cellHeader {
  background-color: $primary-color;
  padding: 0.5em;
  display: grid;
  grid-template-columns: auto 1fr  auto auto;
  align-items: center;
  grid-column-gap: 0.5em
}

.justifyEnd {
  justify-self: end;
}

.addBall {
  position: fixed;
  background-color: $accent-color;
  width: 3em;
  height: 3em;
  border-radius: 1.5em;
  right: 2em;
  bottom: 2em;
  display: grid;
  justify-content: center;
  align-content: center;
}