import {ReactElement, ReactNode} from "react";
import {Responsive, WidthProvider} from "react-grid-layout";
import "react-grid-layout/css/styles.css"
import "react-resizable/css/styles.css"
import "./GridLayout.scss"
// @ts-ignore
import style from "./GridLayout.module.scss"

const ReactGridLayout = WidthProvider(Responsive);


export type GRID_LAYOUT_PROPS = {
    // which size correlates th which breakpoint (lg is a breakpoint at size > 1200)
    breakpoints?: {
        lg: number,
        md: number,
        sm: number,
        xs: number,
        xxs: number
    },
    // how many columns on which breakpoint to use
    columnCounts?: {
        lg: number,
        md: number,
        sm: number,
        xs: number,
        xxs: number
    },
    rowHeight?: number

    margin?: {
        x: number,
        y: number
    }
    onLayoutChange: (layout: {
        key: string,
        x: number,
        y: number,
        w: number,
        h: number
    }[]) => void

    resizable?: boolean,
    draggable?: boolean,
    dragHandleClassName?: string | false,

    children?: ReactElement<GRID_CELL_PROPS, typeof Cell> | ReactElement<GRID_CELL_PROPS, typeof Cell>[],
    [key: string]: any,
}


export default function ({
                             children,
                             breakpoints = {lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0},
                             columnCounts = {lg: 12, md: 10, sm: 6, xs: 4, xxs: 2},
                             rowHeight = 100,
                             margin = {x: 10, y: 10},
                             onLayoutChange = undefined,
                             resizable = true,
                             draggable = true,
                             dragHandleClassName = undefined,
                             ...props
                         }: GRID_LAYOUT_PROPS) {

    children = Array.isArray(children) ? children : [children]

    return <div {...props} className={`${resizable || style.notResizable} ${props.className}`}>

        <ReactGridLayout
            breakpoints={breakpoints}
            cols={columnCounts}
            rowHeight={rowHeight}
            margin={[margin.x, margin.y]}

            isResizable={resizable}
            resizeHandle={resizable && <div className={style.resizeHandle}/>}
            isDraggable={draggable}
            draggableHandle={dragHandleClassName && draggable ? `.${dragHandleClassName}` : undefined}
            draggableCancel={`.${style.resizeHandle}`}

            onLayoutChange={(l: any) => onLayoutChange(l.map((i: any) => ({
                key: i.i,
                x: i.x,
                y: i.y,
                w: i.w,
                h: i.h
            })))}>

            {children.map(c => c.type({...c.props}))}

        </ReactGridLayout>
    </div>
}

export type GRID_CELL_PROPS = {
    children?: ReactNode
    layout: {
        key: string,
        x: number,
        y: number,
        w: number,
        h: number
        minW?: number,
        maxW?: number,
        minH?: number,
        maxH?: number,
    }
    resizable?: boolean,
    draggable?: boolean,
    [key: string]: any,
}

export function Cell({
                         children,
                         layout,
                         resizable = undefined,
                         draggable = undefined,
                         ...props
                     }: GRID_CELL_PROPS) {

    layout = {...layout, w: Math.max(layout.w, layout.minW), h: Math.max(layout.h, layout.minH)}
    return <div {...props} key={layout.key}
                data-grid={{...layout, isResizable: resizable, isDraggable: draggable, i: layout.key}}
                className={`${style.cell} ${resizable === false && style.notResizable} ${props.className}`}>

        {children}

    </div>

}