
.blur {
  filter: blur(4px);
  transition: filter 0.25s
}

.noBlur {
  filter: none;
  transition: filter 0.25s
}
