export default function ({cols, rows, row, selected, setSelected, className = ""}) {
    return (
        <table className={className} style={cols[0].width ? {tableLayout: "fixed"} : {}}>

            <thead>
            <tr>
                {selected && setSelected &&
                    <th onClick={e => setSelected(rows.filter(r => !selected.includes(r)))}>
                        Select
                    </th>
                }
                {cols.map(c => c.name
                    ? <th style={{width: c.width}} key={c.name}>{c.name}</th>
                    : <th key={c}>{c}</th>
                )}
            </tr>
            </thead>
            <tbody>
            {rows.map((r, i) => (<tr key={i}>
                    {selected && setSelected && <td>
                        {r.id && <input type="checkbox"
                                        checked={selected.includes(r)}
                                        onChange={e => e.target.checked ?
                                            setSelected([...selected, r]) : setSelected(selected.filter(i => i !== r))}/>}
                    </td>}
                    {row(r)}
                </tr>)
            )}
            </tbody>
        </table>
    )


}