import React, {useEffect, useState} from "react";
import style from "./Pools.module.scss";
import Modal from "../../components/Modal";
import EditableValue from "../../components/form/EditableValue";
import {POOL_TYPES, TEMPLATE_ROW} from "./Pools";
import Select, {Option} from "../../components/form/Select";
import {toCHF} from "../../lib/util";
import BluredValue from "../../components/BluredValue";

export default function ({
                             pools,
                             selected,
                             setSelected,
                             updatePool,
                             createPool,
                             deletePool,
                         }) {

    const [edit, setEdit] = useState(false);

    useEffect(() => {
        if (!edit) return
        setEdit(pools.find(p => p.id === edit.id))
    }, [pools]);

    const save = () => {
        if (edit.id) updatePool(edit);
        else createPool(edit);
        setEdit(false)
    }

    return <>
        {pools.map(p => <div key={p.id} className={style.poolCard}>
            <input type={"checkbox"} onChange={e => e.target.checked ?
                setSelected([...selected, p]) : setSelected(selected.filter(i => i !== p))}
            />
            <p>{p.name}</p>

            <BluredValue className={style.gridEnd} value={toCHF(p.balance || 0)}/>

            <span className={`material-symbols-outlined ${style.edit}`} onClick={() => setEdit(p)}>edit</span>

            <p>{p.type}</p>
        </div>)}

        <div className={style.addBallContainer}>
            <div className={style.addBall} onClick={() => setEdit(TEMPLATE_ROW())}>
                <span className="material-symbols-outlined">add</span>
            </div>
        </div>

        <Modal title={(edit.id ? "Edit" : "Create") + " Pool"} visible={!!edit} setVisible={() => setEdit(false)}>
            <div className={style.modal}>

                <label>Name</label>
                <div>
                    <EditableValue value={edit.name} onChange={e => {
                        setEdit({...edit, name: e.target.value})
                    }} inputProps={{required: true}}/>
                </div>

                <label>Type</label>
                <div>
                    <Select value={edit.type} onChange={(e, t) => {
                        setEdit({...edit, type: t})
                    }} required={true}>
                        {POOL_TYPES.map(t => <Option key={t} value={t}>{t}</Option>)}
                    </Select>
                </div>

                <div>
                    {edit.id && <button onClick={() => deletePool(edit) && setEdit(false)}>Delete</button>}
                </div>
                <div className={style.gridEnd}>
                    <button onClick={save}>Save</button>
                </div>
            </div>

        </Modal>

    </>
}