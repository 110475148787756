import {SyntheticEvent, useEffect, useState} from "react";
import Select, {SELECT_PROPS} from "./Select";
// @ts-ignore
import style from "./EditableSelect.module.scss"


export type EDITABLE_SELECT_PROPS<T> = {
    valid?: boolean
    className?: string,
} & SELECT_PROPS<T>

export default function <T>({
                                children,
                                onChange,
                                value,
                                required = false,
                                valid = true,
                                className = "",
                                ...props
                            }: EDITABLE_SELECT_PROPS<T>) {

    const [realValue, setRealValue] = useState<T>(value);

    useEffect(() => {
        setRealValue(value)
    }, [value])

    //@ts-ignore
    const save = (e: SyntheticEvent) => value !== realValue && onChange({
        ...e,
        target: {...e.target, value: realValue}
    }, realValue)

    return (
        <Select {...props}
                className={`${style.select} ${!valid || (required && !realValue) && style.invalid} ${className}`}
                onChange={(e, v) => setRealValue(v)}
                value={realValue}
                onBlur={save} onAbort={save} onKeyDown={e => e.key === "Escape" && save(e)}
                required={required}>

            {children}
        </Select>
    )
}

