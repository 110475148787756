@import "../../../index";

.form, .formGroup {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.formGroup {
  padding-left: 1em;


  border-left-style: solid;
  //border-left-width: 1px;
  border-left-color: $secondary-color;
}


.formElement {
  display: flex;
  flex-direction: column;
  padding-bottom: 1em;

  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: $secondary-color;
}

.formLabel {
  padding-left: 2px;
}

div.formLabel {
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.formInput {
  margin-top: 0.5em;
}

div.formInput {
  display: flex;
  width: 100%;
  justify-items: center;
  gap: 2em
}


select.formInput, .formInput select {
  width: 100%;
}


.buttonGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

