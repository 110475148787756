import KeySearchBox, {SelectedKeys, Value} from "./KeySearchBox";
import {useEffect, useState} from "react";
import {getJson, useApi} from "../../lib/service";
import {DEFAULT_DATE_FORMAT, fromCHF, millisFromDate} from "../../lib/util";

export default function ({setApiQuery}) {

    const [query, setQuery] = useState<SelectedKeys>({})

    const [tags, setTags] = useState<Value[]>([]);
    const [pools, setPools] = useState<Value[]>([]);
    const [schedules, setSchedules] = useState<Value[]>([]);

    useApi(async () => {
        setTags((await getJson<any[]>("/tags")).map(t => ({name: t.name})))
        setPools((await getJson<any[]>("/pools")).map(t => ({name: t.name})))
        setSchedules((await getJson<any[]>("/schedules")).map(t => ({name: t.name})))
    }, []);

    useEffect(() => {
        let filter: string[] = []
        if (query.default && query.default[0])
            filter.push("name contains \"" + query.default[0].name + "\"")
        if (query.amount && query.amount[0])
            filter.push("amount == \"" + fromCHF(query.amount[0].name) + "\"")
        if (query.tag)
            query.tag.forEach(t => filter.push("tag.name == \"" + t.name + "\""))
        if (query.category && query.category[0])
            filter.push("category.name == \"" + query.category[0].name + "\"")
        if (query.source && query.source[0])
            filter.push("source.name == \"" + query.source[0].name + "\"")
        if (query.destination && query.destination[0])
            filter.push("destination.name == \"" + query.destination[0].name + "\"")
        if (query.schedule && query.schedule[0])
            filter.push("schedule.name == \"" + query.schedule[0].name + "\"")
        if (query.from && query.from[0])
            filter.push("date >= \"" + millisFromDate(DEFAULT_DATE_FORMAT, query.from[0].name) + "\"")
        if (query.to && query.to[0])
            filter.push("date <= \"" + millisFromDate(DEFAULT_DATE_FORMAT, query.to[0].name) + "\"")
        if (query.date && query.date[0])
            filter.push("date == \"" + millisFromDate(DEFAULT_DATE_FORMAT, query.date[0].name) + "\"")
        if (query.query && query.query[0])
            filter.push(query.query[0].name)

        setApiQuery(filter.join(" and "))
    }, [query]);


    return <KeySearchBox query={query} setQuery={setQuery} keys={{
        default: {
            complete: (v) => v ? [{name: v}] : [],
            allowMulti: false
        },
        amount: {
            complete: (v) => {
                const n = v.split(":")[1]
                if (/^-?\d*(\.\d{1,2})?$/.test(n)) return [{name: n}];
                else return []
            },
            allowMulti: false
        },
        tag: {
            complete: (v) => tags,
            allowMulti: true
        },
        category: {
            complete: (v) => tags,
            allowMulti: false
        },
        source: {
            complete: (v) => pools,
            allowMulti: false
        },
        destination: {
            complete: (v) => pools,
            allowMulti: false
        },
        schedule: {
            complete: (v) => schedules,
            allowMulti: false
        },
        from: {
            complete: (v) => {
                const n = v.split(":")[1]
                if (/^\d{1,2}\.\d{1,2}\.\d{4}$/.test(n)) return [{name: n}];
                else return []
            },
            allowMulti: false
        },
        to: {
            complete: (v) => {
                const n = v.split(":")[1]
                if (/^\d{1,2}\.\d{1,2}\.\d{4}$/.test(n)) return [{name: n}];
                else return []
            },
            allowMulti: false
        },
        date: {
            complete: (v) => {
                const n = v.split(":")[1]
                if (/^\d{1,2}\.\d{1,2}\.\d{4}$/.test(n)) return [{name: n}];
                else return []
            },
            allowMulti: false
        },
        query: {
            complete: (v) => {
                const n = v.split(":")[1]
                return [{name: n}];
            },
            allowMulti: false
        }
    }}/>


}