.content {
  padding: 0 0.5em;
}

.spacer {
  height: 1em;
}

@media only screen and (min-width: 1000px) {
  .container {
    display: flex;
    flex-direction: row;
    height: 100dvh;
  }
  .main {
    height: 100dvh;
    width: 100%;
    display: flex;
    overflow: scroll;
    justify-content: center;
  }
  .content {
    padding: 2em 2em;
    max-width: 1200px;
    width: 100%;
  }
}

