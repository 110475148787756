@import "../../index";


.infoCard {
  @extend .card;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  grid-column-gap: 1em;
}


.entry {
  padding: 1em 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: $secondary-color;
}