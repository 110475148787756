import Table from "../../components/table/Table";
import style from "./Transactions.module.scss";
import EditableValue from "../../components/form/EditableValue";
import {formatDateISO, millisFromISO} from "../../lib/util";
import Select, {Option} from "../../components/form/Select";
import CHFInput from "../../components/form/CHFInput";
import {useState} from "react";
import {TEMPLATE_ROW} from "./Transactions";
import TagSelect from "../../components/form/TagSelect";
import TagMultiSelect from "../../components/form/TagMultiSelect";

const TABLE_HEADERS = ["Date", "Name", "Category", "Tags", "Schedule", "Source", "Destination", "Amount", "Actions",]

export default function ({
                             transactions,
                             updateTransaction,
                             deleteTransaction,
                             createTransaction,
                             selected,
                             setSelected,
                             tags,
                             pools
                         }) {

    const [empty, setEmpty] = useState(TEMPLATE_ROW());

    return <Table cols={TABLE_HEADERS} rows={[empty, ...transactions]} className={style.table}
                  selected={selected} setSelected={setSelected}
                  row={r => <>
                      <td>
                          <EditableValue value={formatDateISO(r.transactionDate)} onChange={e => {
                              if (!r.id) setEmpty({...r, transactionDate: millisFromISO(e.target.value)})
                              else updateTransaction({...r, transactionDate: millisFromISO(e.target.value)})
                          }} inputProps={{required: true, type: "date", className: style.dateInput}}/>
                      </td>
                      <td>
                          <EditableValue value={r.name} onChange={e => {
                              if (!r.id) setEmpty({...r, name: e.target.value})
                              else updateTransaction({...r, name: e.target.value})
                          }} inputProps={{required: true}}/>
                      </td>
                      <td>
                          <TagSelect tags={tags} value={r.category} onChange={(e, c) => {
                              if (!r.id) setEmpty({...r, category: c})
                              else updateTransaction({...r, category: c})
                          }} required={true}/>
                      </td>
                      <td>
                          <TagMultiSelect tags={tags.filter(t => t.id !== r.category?.id)} selected={r.tags || []}
                                          setSelected={t => {
                                              if (!r.id) setEmpty({...r, tags: t || []})
                                              else updateTransaction({...r, tags: t || []})
                                          }}/>
                      </td>
                      <td><p className={style.schedule}>{r.schedule?.name || "-"}</p></td>
                      <td>
                          <Select value={r.source} onChange={(e, s) => {
                              if (!r.id) setEmpty({...r, source: s})
                              else updateTransaction({...r, source: s})
                          }} required={true}>
                              {pools.map(p => <Option key={p.id} value={p}>{p.name}</Option>)}
                          </Select>
                      </td>
                      <td>
                          <Select value={r.destination} onChange={(e, s) => {
                              if (!r.id) setEmpty({...r, destination: s})
                              else updateTransaction({...r, destination: s})
                          }} required={true}>
                              {pools.map(p => <Option key={p.id} value={p}>{p.name}</Option>)}
                          </Select>
                      </td>

                      <td>
                          <CHFInput value={r.amount} onChange={e => {
                              if (!r.id) setEmpty({...r, amount: e.target.value})
                              else updateTransaction({...r, amount: e.target.value})
                          }} inputProps={{required: true, className: `${style.amountInput}`}}/>
                      </td>

                      <td>
                          {r.id && <button onClick={() => deleteTransaction(r)}>Delete</button>}
                          {!r.id &&
                              <button onClick={() => createTransaction(r) && setEmpty(TEMPLATE_ROW())}>Save</button>}
                      </td>
                  </>}/>
}