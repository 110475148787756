@import "../index";

.loading {
  display: flex;
  justify-content: center;
  padding: 2em;
}

.spinner {
  width: 48px;
  height: 48px;
  border: 5px solid $text-color;
  border-bottom-color: $primary-color;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.paused {
  font-size: 52px !important;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
