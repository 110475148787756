@import "../../index";

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
}

.workspaceWrapper {
  max-height: calc(100% - 4em);
  flex: 1;
  display: flex;
  flex-direction: column;
}

.workspace {
  max-height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.edit {
  height: 100%;
  max-height: 100%;
  overflow: scroll;

  padding: 1em;
  border-right-style: solid;
  border-right-color: $secondary-color;
}

.view {
  height: 100%;
  max-height: 100%;
  overflow: scroll;

  padding: 1em;
}