import Table from "../../components/table/Table";
import EditableValue from "../../components/form/EditableValue";
import {useState} from "react";
import {TEMPLATE_ROW} from "./Tags";

const TABLE_HEADERS = ["Name", "Color", "Actions"]

export default function ({
                             tags, selected, setSelected, updateTag, createTag, deleteTag,
                         }) {

    const [empty, setEmpty] = useState(TEMPLATE_ROW());

    return <Table cols={TABLE_HEADERS} rows={[empty, ...tags]}
                  selected={selected} setSelected={setSelected}
                  row={r => <>
                      <td>
                          <EditableValue value={r.name} onChange={e => {
                              if (!r.id) setEmpty({...r, name: e.target.value})
                              else updateTag({...r, name: e.target.value})
                          }} inputProps={{required: true}}/>
                      </td>
                      <td>
                          <EditableValue value={r.color} onChange={e => {
                              if (!r.id) setEmpty({...r, color: e.target.value})
                              else updateTag({...r, color: e.target.value})
                          }} inputProps={{required: true, type: "color"}}/>
                      </td>
                      <td>
                          {r.id && <button onClick={() => deleteTag(r)}>Delete</button>}
                          {!r.id && <button onClick={() => createTag(r) && setEmpty(TEMPLATE_ROW())}>Save</button>}
                      </td>
                  </>}/>
}