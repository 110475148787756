import {MouseEventHandler} from "react";
// @ts-ignore
import style from "./Tag.module.scss"

export type TAG_PROPS = {
    tag: { name: string, color: string }
    onClick?: MouseEventHandler<HTMLButtonElement>,
    className?: string
}

export default function ({tag, onClick = undefined, className = ""}: TAG_PROPS) {
    return <button onClick={onClick} disabled={!onClick}
                   className={`${style.tag} ${!onClick && style.disabled} ${className}`}
                   style={{color: tag.color, backgroundColor: tag.color}}>
        {tag.name}
    </button>
}