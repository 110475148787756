import Table from "../../components/table/Table";
import EditableValue from "../../components/form/EditableValue";
import CronInput from "../../components/form/CronInput";
import style from "./Synthesise.module.scss";
import Select, {Option} from "../../components/form/Select";
import {formatDateISO, millisFromISO} from "../../lib/util";
import {CRON_OPTIONS, SCHEDULE_TEMPLATE_ROW} from "./Synthesise";
import {useState} from "react";

const SCHEDULE_TABLE_HEADERS = ["Name", "Rule", "Prefab", "Star", "End", "Actions"]
export default function ({
                             schedules,
                             selectedSchedules,
                             setSelectedSchedules,
                             updateSchedule,
                             createSchedule,
                             deleteSchedule,
                             disableSchedule,
                             enableSchedule,
                             prefabs
                         }) {

    const [empty, setEmpty] = useState(SCHEDULE_TEMPLATE_ROW());

    return <Table cols={SCHEDULE_TABLE_HEADERS} rows={[empty, ...schedules]}
                  selected={selectedSchedules} setSelected={setSelectedSchedules}
                  row={r => <>
                      <td>
                          <EditableValue value={r.name} onChange={e => {
                              if (!r.id) setEmpty({...r, name: e.target.value})
                              else updateSchedule({...r, name: e.target.value})
                          }} inputProps={{required: true}}/>
                      </td>

                      <td>
                          <CronInput cron={CRON_OPTIONS} value={r.cron} onChange={e => {
                              if (!r.id) setEmpty({...r, cron: e.target.value})
                              else updateSchedule({...r, cron: e.target.value})
                          }} className={style.cronInput}/>
                      </td>

                      <td>
                          <Select value={r.prefab} onChange={(e, p) => {
                              if (!r.id) setEmpty({...r, prefab: p})
                              else updateSchedule({...r, prefab: p})
                          }} required={true}>
                              {prefabs.map(p => <Option key={p.id} value={p}>{p.name}</Option>)}
                          </Select>
                      </td>

                      <td>
                          <EditableValue value={formatDateISO(r.start)} onChange={e => {
                              if (!r.id) setEmpty({...r, start: millisFromISO(e.target.value)})
                              else updateSchedule({...r, start: millisFromISO(e.target.value)})
                          }} inputProps={{required: true, type: "date", className: style.dateInput}}/>
                      </td>

                      <td className={style.dateInput}>
                          <EditableValue value={formatDateISO(r.end)} onChange={e => {
                              if (!r.id) setEmpty({...r, end: millisFromISO(e.target.value)})
                              else updateSchedule({...r, end: millisFromISO(e.target.value)})
                          }} inputProps={{required: false, type: "date", className: style.dateInput}}/>
                      </td>

                      <td>
                          {r.id && <button onClick={() => deleteSchedule(r)}>Delete</button>}
                          {r.id && r.enabled && <button onClick={() => disableSchedule(r)}>Disable</button>}
                          {r.id && !r.enabled && <button onClick={() => enableSchedule(r)}>Enable</button>}
                          {!r.id && <button
                              onClick={() => createSchedule(r) && setEmpty(SCHEDULE_TEMPLATE_ROW())}>Save</button>}
                      </td>

                  </>}/>
}