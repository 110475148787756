@import "../index";

.modalBG {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);

  display: grid;
  justify-content: center;
  align-items: start;
}

.modal {
  position: relative;
  background-color: $secondary-color;
  margin: 100px 1em;
  padding: 1em;

  border: 1px solid $tertiary-color;
  border-radius: 1em;
  min-width: 30vw;
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s
}

@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0
  }
  to {
    top: 0;
    opacity: 1
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0
  }
  to {
    top: 0;
    opacity: 1
  }
}

.modalHeader {
  padding-bottom: 1em;
  display: flex;
  justify-content: space-between;
}
