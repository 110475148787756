import React, {useEffect, useState} from "react";
import style from "./DashboardCellForm.module.scss";
import formStyle from "./cell/CellForm.module.scss";
import Select, {Option} from "../../components/form/Select";
import EditableValue from "../../components/form/EditableValue";
import {useLayout} from "./Dashboard";
import {useNavigate, useSearchParams} from "react-router-dom";
import toastr from "../../lib/toastr";
import ListCell, {DEFAULT_CONFIG as listConfig, Form as ListForm} from "./cell/ListCell";
import PieChartCell, {DEFAULT_CONFIG as pieConfig, Form as PieForm} from "./cell/PieChartCell";
import SpiderChartCell, {DEFAULT_CONFIG as spiderConfig, Form as SpiderForm} from "./cell/SpiderChartCell";
import AxisChartCell, {DEFAULT_CONFIG as axisConfig, Form as AxisForm} from "./cell/AxisChartCell";


export const CELL_TYPES = {
    "list": {form: ListForm, cell: ListCell},
    "pie": {form: PieForm, cell: PieChartCell},
    "spider": {form: SpiderForm, cell: SpiderChartCell},
    "axis": {form: AxisForm, cell: AxisChartCell}
}

const DEFAULT_CONFIGS = {
    "list": listConfig,
    "pie": pieConfig,
    "spider": spiderConfig,
    "axis": axisConfig
}

export default function () {

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const [id, setId] = useState("");

    const {addCell, editCell, removeCell, cellConfig} = useLayout()

    const [type, setType] = useState("list")
    const [name, setName] = useState("My new list")
    const [config, setConfig] = useState(DEFAULT_CONFIGS)


    const [showDebug, setShowDebug] = useState(false)

    useEffect(() => {
        const id = searchParams.get("id");
        if (!id || !cellConfig) return;

        const c = cellConfig[id]
        setConfig({...config, [c.type]: c})
        setName(c.name)
        setType(c.type)
        setId(id)
    }, [searchParams]);

    const save = () => {
        if (id && cellConfig[id]) {
            editCell(id, {...config[type], name, type})
            toastr.success("Changes saved")
        } else {
            addCell({...config[type], name, type})
            toastr.success("Cell created")
        }
        console.log({...config[type], name, type})
        navigate("/dashboard")
    }

    const _delete = () => {
        removeCell(id)
        navigate("/dashboard")
    }


    const Cell = CELL_TYPES[type].cell;
    const Form = CELL_TYPES[type].form;

    return <div className={style.container}>

        <div className={style.sectionHead}>
            <h1>Edit Dashboard Cell</h1>
        </div>


        <div className={style.workspaceWrapper}>
            <div className={style.workspace}>
                <div className={style.edit}>

                    <div className={formStyle.form}>

                        <div className={formStyle.formElement}>
                            <label className={formStyle.formLabel}>Name</label>
                            <EditableValue valid={false} value={name} onChange={e => setName(e.target.value)}
                                           inputProps={{className: formStyle.formInput}}/>
                        </div>

                        <div className={formStyle.formElement}>
                            <label className={formStyle.formLabel}>Display</label>
                            <Select value={type} onChange={(e, v) => setType(v)} required={true}
                                    className={formStyle.formInput}>
                                {Object.keys(CELL_TYPES).map(k => <Option key={k} value={k}>{k}</Option>)}
                            </Select>
                        </div>

                        <Form config={config[type]}
                              setConfig={(c) => setConfig({...config, [type]: c})}/>

                        <div className={formStyle.formElement}>
                            <div className={formStyle.formLabel}>
                                <label>Debug</label>
                                {!showDebug && <span className="material-symbols-outlined" title={"Expand"}
                                                     onClick={() => setShowDebug(true)}>expand_more</span>}
                                {showDebug && <span className="material-symbols-outlined" title={"Collapse"}
                                                    onClick={() => setShowDebug(false)}>expand_less</span>}
                            </div>
                            {showDebug && <p className={formStyle.formInput}>{JSON.stringify(config[type])}</p>}
                        </div>

                        <div className={formStyle.buttonGroup}>
                            {id && <button onClick={_delete}>Delete</button>}
                            <button onClick={save}>Save</button>
                        </div>
                    </div>
                </div>


                <div className={style.view}>
                    <Cell config={config[type]}/>
                </div>
            </div>
        </div>


    </div>
}

