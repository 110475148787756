import React, {useEffect, useState} from "react";
import style from "./Synthesise.module.scss";
import {toCHF} from "../../lib/util";
import Modal from "../../components/Modal";
import EditableValue from "../../components/form/EditableValue";
import CHFInput from "../../components/form/CHFInput";
import Select, {Option} from "../../components/form/Select";
import TagMultiSelect from "../../components/form/TagMultiSelect";
import {PREFAB_TEMPLATE_ROW} from "./Synthesise";
import Tag from "../../components/Tag";
import TagSelect from "../../components/form/TagSelect";
import BluredValue from "../../components/BluredValue";

export default function ({
                             prefabs,
                             selectedPrefabs,
                             setSelectedPrefabs,
                             updatePrefab,
                             createPrefab,
                             deletePrefab,
                             tags,
                             pools
                         }) {

    const [edit, setEdit] = useState(false);

    useEffect(() => {
        if (!edit) return
        setEdit(prefabs.find(p => p.id === edit.id))
    }, [prefabs]);

    const save = () => {
        if (edit.id) updatePrefab(edit);
        else createPrefab(edit);
        setEdit(false)
    }

    return <>
        {prefabs.map(p => <div key={p.id} className={style.prefabCard}>
            <input type={"checkbox"} onChange={e => e.target.checked ?
                setSelectedPrefabs([...selectedPrefabs, p]) : setSelectedPrefabs(selectedPrefabs.filter(i => i !== p))}
            />
            <p>{p.name}</p>

            <div className={style.gridEnd}>
                {(p.source || p.destination) && <p>{p.source?.name} -> {p.destination?.name}</p>}
            </div>

            <span className={`material-symbols-outlined ${style.edit}`} onClick={() => setEdit(p)}>edit</span>

            <p>
                {p.category && <Tag title={"Category"} className={style.bold} tag={p.category}/>}
                {(p.tags || []).map(t => <Tag key={t.id} tag={t}/>)}
            </p>

            <BluredValue className={style.gridEnd} value={toCHF(p.amount)}/>
        </div>)}

        <div className={style.addBallContainer}>
            <div className={style.addBall} onClick={() => setEdit(PREFAB_TEMPLATE_ROW())}>
                <span className="material-symbols-outlined">add</span>
            </div>
        </div>

        <Modal title={(edit.id ? "Edit" : "Create") + " Prefab"} visible={!!edit}
               setVisible={() => setEdit(false)}>
            <div className={style.modal}>

                <label>Name</label>
                <div>
                    <EditableValue value={edit.name} onChange={e => {
                        setEdit({...edit, name: e.target.value})
                    }} inputProps={{required: true}}/>
                </div>

                <label>Amount</label>
                <div>
                    <CHFInput value={edit.amount} onChange={e => {
                        setEdit({...edit, amount: e.target.value})
                    }} inputProps={{required: true}}/>
                </div>

                <label>Category</label>
                <div>
                    <TagSelect tags={tags} value={edit.category} onChange={(e, c) => {
                        setEdit({...edit, category: c})
                    }}/>
                </div>

                <label>Tags</label>
                <div>
                    <TagMultiSelect tags={tags.filter(t => t.id !== edit.category?.id)} selected={edit.tags || []}
                                    setSelected={t => {
                                        setEdit({...edit, tags: t || []})
                                    }}/>
                </div>

                <label>Source</label>
                <div>
                    <Select value={edit.source} onChange={(e, s) => {
                        setEdit({...edit, source: s})
                    }}>
                        {pools.map(p => <Option key={p.id} value={p}>{p.name}</Option>)}
                    </Select>
                </div>

                <label>Destination</label>
                <div>
                    <Select value={edit.destination} onChange={(e, s) => {
                        setEdit({...edit, destination: s})
                    }}>
                        {pools.map(p => <Option key={p.id} value={p}>{p.name}</Option>)}
                    </Select>
                </div>


                <div>
                    {edit.id && <button onClick={() => deletePrefab(edit) && setEdit(false)}>Delete</button>}
                </div>
                <div className={style.gridEnd}>
                    <button onClick={save}>Save</button>
                </div>

            </div>

        </Modal>

    </>
}