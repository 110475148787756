import {ChangeEvent, SyntheticEvent} from "react";

export type SelectChangeEvent<T> = SyntheticEvent<HTMLSelectElement> & {
    target: {
        value: T
    }
} | ChangeEvent<HTMLSelectElement>

export type SELECT_PROPS<T> = {
    children: JSX.Element[]
    onChange: (e: SelectChangeEvent<T>, t: T) => void,
    value: T | undefined,
    required?: boolean,
    [key: string]: any
}

export default function <T>({children, onChange, value, required = false, ...props}: SELECT_PROPS<T>) {

    const options = children ? children.flatMap(e => Array.isArray(e) ? e : [e]) : [];
    const optionsById = Object.fromEntries(options.map(o => [objId(o.props?.value), o.props?.value]));
    const selectedValue: any = value && optionsById[objId(value)] || ""

    return (
        <select {...props} value={objId(selectedValue)}
                onChange={e =>
                    onChange(e, optionsById[e.target.options[e.target.selectedIndex].value])
                }>

            {required &&
                <option style={{display: "none"}} disabled={true} value={""}>-</option>}
            {!required &&
                <option value={"null"}>-</option>}

            {options}
        </select>
    )
}

export type OPTION_PROPS<T> = {
    value: T,
    children: string,
    disabled?: boolean,
    [key: string]: any
}

export function Option<T>({value, children, disabled, ...props}: OPTION_PROPS<T>) {
    return <option {...props} {...(disabled ? {disabled: true, style: {display: "none"}} : {})}
                   value={objId(value)}>{children}</option>
}

function objId(o: any, depth = 0): string {
    if (depth > 3) return "";

    if (o === undefined || o === null || o === false || typeof o === 'string' || typeof o === 'number') return o + "";
    if (o.id) return o.id + "";

    let hash = 0;
    const keys = Object.keys(o).sort();

    for (let key of keys) {
        const h = objId(o[key], depth + 1);
        for (let i = 0; i < h.length; i++) {
            hash = ((hash << 5) - hash) + h.charCodeAt(i);
            hash |= 0;
        }
    }
    return hash + "";
}