import {useEffect, useRef, useState} from "react";
import {useApi} from "../lib/service";
import style from "./PagableFetcher.module.scss";

const isOnScreen = e => {
    const rect = e.getBoundingClientRect();
    const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
    return !(rect.bottom < 0 || rect.top - viewHeight >= 500);
}

export default function ({fetch, trigger = false}) {

    const [schedule, setSchedule] = useState(0);
    const [stopped, setStopped] = useState(false);
    const ref = useRef();


    useApi(async () => {
        if (schedule === false) return
        if (stopped || !isOnScreen(ref.current) || await fetch()) {
            setTimeout(() => setSchedule(schedule + 1), 1000);
        } else {
            setSchedule(false)
        }
    }, [schedule]);

    useEffect(() => {
        setSchedule(0)
    }, [trigger]);


    return (
        <div className={style.loading} ref={ref}>
            {schedule !== false && !stopped && <span onClick={() => setStopped(true)} className={style.spinner}></span>}
            {stopped && <span className={`material-symbols-outlined ${style.paused}`}
                              onClick={() => setStopped(false)}>motion_photos_paused</span>}
            {schedule === false && !stopped && <p onClick={() => setSchedule(true)}>You have reached the end</p>}
        </div>
    )

}