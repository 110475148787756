@import '../index';

.container {
  background-color: $secondary-color;
  padding: 1.5em;
  position: sticky;
  top: 0;
  z-index: 99;
}

.ball {
  background-color: $accent-color;
  border-radius: 1em;
  width: 2em;
  height: 2em;

  position: absolute;
  bottom: -1em;
  right: 1em;

  display: grid;
  justify-content: center;
  align-content: center;
  transform: rotate(90deg);
  transition: transform 0.25s;
  cursor: pointer;
  user-select: none;
}

.ballCollapsed {
  transform: rotate(270deg);
}

.content {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.menu {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1.5em;
  max-height: 100vh;
  transition: max-height 0.5s;
}

.menuCollapsed {
  max-height: 0;
}

.nav {
  padding-top: 1em;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.navItem {
  display: flex;
  align-items: end;
  gap: 4px;
  color: $tertiary-color;
  transition: padding-left 0.25s;
}

.activeNavItem {
  padding-left: 1em;
  border-left-style: solid;
  border-left-color: $accent-color;
  color: $text-color;
}

.footer {
  border-top-style: solid;
  border-top-width: 1px;
  padding-top: 1em;
  justify-self: end;
}

@media only screen and (min-width: 1000px) {
  .container {
    position: relative;
    height: 100dvh;
  }
  .content {
    height: 100%;
    width: 10em;
    transition: width 0.25s;
  }
  .titleCollapsed {
    display: none;
  }
  .ball {
    position: absolute;
    bottom: initial;
    right: -1em;
    top: 1em;

    transform: rotate(0deg);
  }

  .ballCollapsed {
    transform: rotate(-180deg);
  }
  .menuCollapsed {
    max-height: 100vh;
  }

  .contentCollapsed {
    visibility: hidden;
    width: 0;
    max-height: 100%;
  }
}