@import "../../index";

.container {
  display: flex;
  width: 100%;
  background-color: $text-color;
  border-radius: 3px;
}

.option {
  background-color: $tertiary-color;
}

.input, .input:active, .input:focus {
  justify-self: stretch;
  margin-left: 3px;
  border-style: none;
  outline: none;
}

.invalid {
  color: $tertiary-color;
}

