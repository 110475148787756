import style from "./Overview.module.scss"
import {useState} from "react";
import Select, {Option} from "../../components/form/Select";
import {getJson} from "../../lib/service";
import {cleanCopyDate, DAYS, formatDateISO, MONTHS, toCHF, weekOfYear} from "../../lib/util";
import PagableFetcher from "../../components/PagableFetcher";
import MediaQuery from "react-responsive";
import OverviewDesktop from "./OverviewDesktop";
import OverviewMobile from "./OverviewMobile";
import BluredValue from "../../components/BluredValue";


const PERIODS = {
    "Daily": start => {
        const date = cleanCopyDate(start);
        date.setDate(date.getDate() + 1)
        return [date.valueOf(), DAYS[start.getDay()] + " " + formatDateISO(start)];
    },
    "Weekly": start => {
        const date = cleanCopyDate(start);
        date.setDate(date.getDate() + 1 + (date.getDay() === 0 ? 0 : (7 - date.getDay())))
        return [date.valueOf(), "KW-" + weekOfYear(start) + " " + start.getFullYear()];
    },
    "Monthly": start => {
        const date = cleanCopyDate(start);
        date.setDate(1)
        date.setMonth(date.getMonth() + 1)
        return [date.valueOf(), MONTHS[start.getMonth()] + " " + start.getFullYear()];
    }
}

export const poolTypeTitle = t => {
    return t.source.name + " -> " + t.destination.name
}
const calcBalanceChange = ts => {
    let change = 0;
    for (const t of ts) {
        if (t.source.type === "BALANCE" && t.destination.type !== "BALANCE")
            change -= t.amount
        if (t.destination.type === "BALANCE" && t.source.type !== "BALANCE")
            change += t.amount
    }
    return change
}

export default function () {

    const [period, setPeriod] = useState("Weekly")
    const [transactions, setTransactions] = useState([])

    const transactionFetcher = async () => {
        const i = Math.ceil((transactions.length - 1) / 50)
        const page = await getJson("/transactions", {page: i, size: "50"})
        setTransactions([...page.reverse(), ...transactions])
        return page.length > 0;
    }

    const grab = ts => {
        if (!ts.length) return []

        const from = new Date(ts[0].transactionDate)
        const [to, name] = PERIODS[period](from)

        const current = []
        while (ts.length !== 0 && to > ts[0].transactionDate)
            current.push(ts.shift())

        return [...grab(ts), {name, transactions: current.reverse()}];
    }

    return (
        <div>
            <div className={style.sectionHead}>
                <h1>Overview</h1>
            </div>

            <div className={style.toolbar}>
                <Select value={period} onChange={(e, v) => setPeriod(v)} required={true}>
                    {Object.keys(PERIODS).map(p => <Option key={p} value={p}>{p}</Option>)}
                </Select>
            </div>

            <div>
                {grab([...transactions]).map(p => <div key={p.name} className={style.card}>
                    <div className={style.cardHeader}>
                        <h2>{p.name}</h2>
                        <BluredValue title="Total change in Balance Pools"
                                     value={toCHF(calcBalanceChange(p.transactions))}/>
                    </div>

                    <MediaQuery minWidth={900}>
                        <OverviewDesktop transactions={p.transactions}/>
                    </MediaQuery>

                    <MediaQuery maxWidth={900}>
                        <OverviewMobile transactions={p.transactions}/>
                    </MediaQuery>

                </div>)}
            </div>

            <PagableFetcher fetch={transactionFetcher}/>
        </div>

    );
}
