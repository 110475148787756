import style from "./Transactions.module.scss";
import {useEffect, useState} from "react";
import {_delete, getJson, postJson, putJson, useApi} from "../../lib/service";
import toastr from "../../lib/toastr";
import PagableFetcher from "../../components/PagableFetcher";
import MediaQuery from "react-responsive";
import TransactionsDesktop from "./TransactionsDesktop";
import TransactionsMobile from "./TransactionsMobile";
import {cleanCopyDate} from "../../lib/util";
import TransactionSearchBox from "../../components/form/TransactionSearchBox";

export const TEMPLATE_ROW = () => ({
    name: "", category: null, source: null,
    destination: null, schedule: null,
    prefab: null, amount: 0, tags: [],
    transactionDate: cleanCopyDate(new Date()).valueOf()
})

const validateTransaction = t => {
    if (!t.name || !t.category || !t.source || !t.destination || !t.amount || !t.transactionDate)
        return false;
    if (t.source === t.destination)
        return false;
    if (t.amount < 0) {
        [t.source, t.destination] = [t.destination, t.source]
        t.amount = -t.amount
    }
    return true;
}

const transactionSort = (t1, t2) => t2.transactionDate - t1.transactionDate || t2.creationDate - t1.creationDate;

export default function () {

    const [transactions, setTransactions] = useState([]);
    const [selected, setSelected] = useState([])
    const [tags, setTags] = useState([])
    const [pools, setPools] = useState([])
    const [apiQuery, setApiQuery] = useState("")
    const [queryError, setQueryError] = useState()


    useApi(async () => {
        setTags(await getJson("/tags"))
        setPools(await getJson("/pools"))
    }, []);

    useEffect(() => {
        setTransactions([])
        setQueryError(null)
    }, [apiQuery]);

    const transactionFetcher = async () => {
        const i = Math.ceil((transactions.length - 1) / 50)
        try {
            const page = await getJson("/transactions", {page: i, size: "50", query: apiQuery})
            setTransactions([...transactions, ...page])
            return page.length === 50;
        } catch (e) {
            if (e.status === 400)
                setQueryError(await e.text())
            return false;
        }
    }

    const createTransaction = async t => {
        if (!validateTransaction(t)) return;
        try {
            t = await postJson("/transactions", {
                transactionDate: t.transactionDate, name: t.name, amount: t.amount,
                category: t.category.id,
                source: t.source.id,
                destination: t.destination.id,
                tags: t.tags?.map(i => i.id) || []
            })
            setTransactions([...transactions, t].sort(transactionSort))
            toastr.success("Created Transaction")
        } catch (e) {
            toastr.warning("Create Failed")
        }
    }

    const updateTransaction = async t => {
        if (!validateTransaction(t)) return
        try {
            t = await putJson("/transactions/" + t.id, {
                transactionDate: t.transactionDate, name: t.name, amount: t.amount,
                category: t.category.id,
                source: t.source.id,
                destination: t.destination.id,
                tags: t.tags?.map(i => i.id) || []
            })
            setTransactions(transactions.map(i => i.id === t.id ? t : i).sort(transactionSort))
            toastr.success("Updated Transaction")
        } catch (e) {
            toastr.warning("Update Failed")
        }
    }

    const deleteTransaction = async t => {
        try {
            await _delete("/transactions/" + t.id)
            setTransactions(transactions.filter(i => i !== t))
            setSelected(selected.filter(i => i !== t))
            toastr.success("Deleted Transaction")
        } catch (e) {
            toastr.warning("Deleted Failed")
        }
    }

    const deleteSelected = async () => {
        if (!confirm("Delete all selected (" + selected.length + ") Transactions?")) return;
        const errors = []
        for (const t of selected)
            await _delete("/transactions/" + t.id).catch(e => errors.push(t))
        setTransactions(transactions.filter(i => !selected.includes(i) || errors.includes(i)))
        setSelected([])
        if (errors.length === 0) toastr.success("Deleted " + selected.length + " Transactions")
        else toastr.warning("Delete Failed, deleted " + (selected.length - errors.length) + " of " + selected.length + " Transactions")
    }

    return (
        <div>

            <div className={style.sectionHead}>
                <h1>Transactions</h1>
            </div>

            <div className={style.toolbar}>
                <button disabled={!selected.length} onClick={deleteSelected}>Delete</button>

                <div className={style.search}>
                    <TransactionSearchBox apiQuery={apiQuery} setApiQuery={setApiQuery}/>
                </div>
            </div>

            <MediaQuery minDeviceWidth={1300}>
                <TransactionsDesktop transactions={transactions} updateTransaction={updateTransaction}
                                     deleteTransaction={deleteTransaction} createTransaction={createTransaction}
                                     selected={selected} setSelected={setSelected} tags={tags} pools={pools}/>
            </MediaQuery>

            <MediaQuery maxDeviceWidth={1300}>
                <TransactionsMobile transactions={transactions} updateTransaction={updateTransaction}
                                    deleteTransaction={deleteTransaction} createTransaction={createTransaction}
                                    selected={selected} setSelected={setSelected} tags={tags} pools={pools}/>
            </MediaQuery>

            {queryError &&
                <div className={style.queryErrorContainer}>
                    <div>Error in Query</div>
                    <div className={style.queryError}>{queryError}</div>
                </div>
            }
            {!queryError && <PagableFetcher fetch={transactionFetcher} trigger={apiQuery}/>}
        </div>
    );
}
