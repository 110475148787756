import Select, {Option} from "./Select";
import TagElement from "../Tag";
import {useEffect, useState} from "react";
import {Tag, tagSort} from "./TagSelect";

export type SELECT_PROPS = {
    tags: Tag[]
    selected: Tag[]
    setSelected: (tags: Tag[]) => void
}


export default function ({tags, selected, setSelected}: SELECT_PROPS) {

    const [picked, setPicket] = useState();

    useEffect(() => {
        if (!picked) return;
        setSelected([picked, ...selected])
        setPicket(undefined)
    }, [picked]);

    return (
        <>
            {tags.length > selected.length && <Select value={picked} onChange={(e, t) => setPicket(t)} required={true}>
                {tags.filter(t => !selected.some(i => i.id == t.id)).sort(tagSort).map(t => <Option key={t.id}
                                                                                                    value={t}>{t.name}</Option>)}
            </Select>}

            {selected.length !== 0 && <div>
                {selected.sort(tagSort).map(t => <TagElement key={t.id} tag={t} onClick={() => {
                        setSelected(selected.filter(i => i.id !== t.id))
                    }}/>
                )}
            </div>}
        </>
    );

}

