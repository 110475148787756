import {useEffect, useState} from "react";
// @ts-ignore
import style from "./KeySearchBox.module.scss"

export type Value = {
    name: string,
    color?: string
}

export type Key = {
    complete: (value: string) => Value[]
    allowMulti: boolean,
}

export type SelectedKeys = {
    [key: string]: Value[],
    default?: Value[],
}

export type KEY_SEARCH_PROPS = {
    keys: {
        [key: string]: Key,
        default: Key
    },
    query: SelectedKeys,
    setQuery: (keys: SelectedKeys) => void
}

export default function ({keys, query, setQuery}: KEY_SEARCH_PROPS) {

    const [typedValue, setTypedValue] = useState("")
    const [hints, setHints] = useState<string[]>([])


    const resolve = (text: string): [string, Key, Value | false] => {
        const q = text.split(":")
        let key: string, value: string;

        if (q.length == 2 && q[0] in keys) {
            [key, value] = q;
        } else [key, value] = ["default", text];

        const match = keys[key].complete(text)
            .find(p => p.name === value)
        return [key, keys[key], match || false];
    }

    const submit = (text: string) => {
        const [keyName, key, value] = resolve(text);
        if (!value) return;

        if (!key.allowMulti) {
            setQuery({...query, [keyName]: [value]})
        } else if (!query[keyName] || !query[keyName].some(v => v === value)) {
            setQuery({...query, [keyName]: [...query[keyName] || [], value]})
        }
        setTypedValue("")
    }

    const drop = (key: string, val: Value) => {
        const newData = {...query};
        newData[key] = newData[key].filter(v => v !== val)
        setQuery(newData)
    }

    useEffect(() => {
        const [keyName, key] = resolve(typedValue);

        let hints = [];
        if (keyName === "default") {
            hints.push(...Object.keys(keys).filter(k => k !== "default").map(k => k + ":"))
            hints.push(...key.complete(typedValue).map(v => v.name))
        } else {
            hints.push(...key.complete(typedValue).map(v => keyName + ":" + v.name))
        }
        setHints(hints)
    }, [typedValue]);


    return <>
        <div className={style.container}>

            {Object.keys(query).map(k =>
                query[k].map(v => (
                    <button
                        className={style.option} {...(v.color ? {style: {backgroundColor: v.color}} : {})}
                        onClick={() => drop(k, v)} key={v.name}>
                        {k === "default" ? v.name : k + ":" + v.name}
                    </button>
                ))
            )}

            <input placeholder={"search"} list="keys"
                   className={`${style.input} ${!hints.includes(typedValue) && style.invalid}`}
                   value={typedValue}
                   onChange={e => setTypedValue(e.target.value)}
                   onKeyDown={e => {
                       if (e.key == "Enter") submit(typedValue)

                       if (e.key == "Backspace" && typedValue.length == 0) {
                           const lastKey = Object.keys(query).reverse().find(k => query[k].length > 0);
                           const lastValue = lastKey && query[lastKey].reverse()[0]
                           if (lastKey && lastValue) drop(lastKey, lastValue)
                       }
                   }}
            />

            <datalist id="keys">
                {hints.map(h => <option key={h}>{h}</option>)}
            </datalist>
        </div>
    </>

}