import React, {useEffect, useState} from "react";
import EditableValue from "../../../components/form/EditableValue";
import {getJson} from "../../../lib/service";
import GroupSelector, {multiGroupId, multiGroupName} from "./GroupSelector";
import style from "../../overview/Overview.module.scss";
import MediaQuery from "react-responsive";
import OverviewDesktop from "../../overview/OverviewDesktop";
import OverviewMobile from "../../overview/OverviewMobile";
import PagableFetcher from "../../../components/PagableFetcher";
import formStyle from "./CellForm.module.scss";
import Select, {Option} from "../../../components/form/Select";
import BluredValue from "../../../components/BluredValue";
import {toCHF} from "../../../lib/util";


export default function ({config}) {

    const myConfig = {...config, groups: config.groups.filter(g => g)}
    const [transactions, setTransactions] = useState([])

    useEffect(() => {
        setTransactions([])
    }, [config.query]);

    const transactionFetcher = async () => {
        const i = Math.ceil((transactions.length - 1) / 50)
        const page = await getJson("/transactions", {page: i, size: "50", query: myConfig.query})
        setTransactions([...page.reverse(), ...transactions])
        return page.length > 0;
    }

    const grab = ts => {
        if (!ts.length) return []
        const groupId = multiGroupId(myConfig.groups, ts[0])
        const groupName = multiGroupName(myConfig.groups, ts[0]).join(", ")
        const current = []
        for (const t of ts) {
            if (multiGroupId(myConfig.groups, t) === groupId)
                current.push(t)
        }
        return [...grab(ts.filter(t => !current.includes(t))), {name: groupName, transactions: current.reverse()}];
    }

    return <>
        {grab([...transactions]).map(p => <div key={p.name} className={style.card}>

            {p.name && <div className={style.cardHeader}>
                <h2>{p.name}</h2>
                {config.aggregator &&
                    <BluredValue title="Total change in Balance Pools"
                                 value={toCHF(AGGREGATOR_FUNCTIONS[config.aggregator](p.transactions))}/>
                }
            </div>}

            <MediaQuery minDeviceWidth={900}>
                <OverviewDesktop transactions={p.transactions}/>
            </MediaQuery>

            <MediaQuery maxDeviceWidth={900}>
                <OverviewMobile transactions={p.transactions}/>
            </MediaQuery>

        </div>)}
        <PagableFetcher fetch={transactionFetcher}/>
    </>
}

export const DEFAULT_CONFIG = {
    query: "",
    aggregator: undefined,
    groups: []
}

const AGGREGATORS = [
    "All (-Expense, +Balance)", "Only expenses (-Expense)",
]

const AGGREGATOR_FUNCTIONS = {
    "All (-Expense, +Balance)": ts => {
        let change = 0;
        for (const t of ts) {
            if (t.source.type === "BALANCE" && t.destination.type === "EXPENSE") change -= t.amount
            if (t.source.type === "EXPENSE" && t.destination.type === "BALANCE") change += t.amount
        }
        return change
    },
    "Only expenses (-Expense)": ts => {
        let change = 0;
        for (const t of ts) {
            if (t.source.type === "BALANCE" && t.destination.type === "EXPENSE") change -= t.amount
        }
        return change
    }
}


export function Form({config, setConfig}) {

    return <>
        <div className={formStyle.formElement}>
            <label className={formStyle.formLabel}>Query</label>
            <EditableValue valid={false} value={config.query}
                           onChange={e => setConfig({...config, query: e.target.value})}
                           inputProps={{className: formStyle.formInput}}/>
        </div>

        <GroupSelector groups={config.groups} setGroups={groups => {
            console.log(groups)
            setConfig({...config, groups})
        }} allowedGroups={[
            "day", "week", "month"
        ]} allowMulti={false} allowNull={true}/>

        {config.groups.length === 1 && <div className={formStyle.formElement}>
            <label className={formStyle.formLabel}>Aggregator</label>
            <Select value={config.aggregator} onChange={(e, v) => setConfig({
                ...config,
                aggregator: v
            })} required={false}
                    className={formStyle.formInput}>
                {AGGREGATORS.map(k => <Option key={k} value={k}>{k}</Option>)}
            </Select>
        </div>}

    </>
}