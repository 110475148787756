import Nav from "./components/Nav";
import Route, {useRoute} from "./Route";
import Overview from "./pages/overview/Overview";
import style from "./App.module.scss";
import Transactions from "./pages/transactions/Transactions";
import Pools from "./pages/pools/Pools";
import Tags from "./pages/tags/Tags";
import Synthesise from "./pages/synthesise/Synthesise";
import {BrowserRouter} from "react-router-dom";
import Settings from "./pages/settings/Settings";
import Dashboard from "./pages/dashboard/Dashboard";
import DashboardCellForm from "./pages/dashboard/DashboardCellForm";
import {useEffect} from "react";
import toastr from "./lib/toastr";

export function App() {

    const routes = useRoute([
        {path: "/overview", page: Overview},
        {path: "/", page: Overview},
        {path: "/dashboard", page: Dashboard},
        {path: "/dashboard/edit", page: DashboardCellForm},
        {path: "/transactions", page: Transactions},
        {path: "/tags", page: Tags},
        {path: "/pools", page: Pools},
        {path: "/synthesise", page: Synthesise},
        {path: "/settings", page: Settings},
    ])


    useEffect(() => {
        window.addEventListener("load", () => toastr.warning("Your viewing a demo, changes will be reverted every 24h"))
    }, [])


    return (
        <BrowserRouter>
            <div className={style.container}>
                <Nav/>
                <main className={style.main}>
                    <div className={style.content}>
                        <Route route={routes}/>
                        <div className={style.spacer}></div>
                    </div>
                </main>
            </div>
        </BrowserRouter>
    );
}
