import {SyntheticEvent, useEffect, useState} from "react";
import {EDITABLE_VALUE_PROPS} from "./EditableValue";
import {fromCHF, toCHF} from "../../lib/util";
import matheval from "../../lib/matheval";
import {useBlur} from "../BluredValue";
// @ts-ignore
import style from "./EditableValue.module.scss";

export type ValuedChangeEvent = SyntheticEvent & { target: { value: number } }

export type NUMERIC_INPUT_PROPS = EDITABLE_VALUE_PROPS & {
    value: number | undefined,
    onChange: (e: ValuedChangeEvent) => void,
}

export default function ({
                             value,
                             onChange,
                             valid = true,
                             inputProps = {}
                         }: NUMERIC_INPUT_PROPS) {

    const [realValue, setRealValue] = useState<string>("");
    const [numberValid, setNumberValid] = useState(valid);

    const [blurStyle, setBlur] = useBlur();

    const save = (e: ValuedChangeEvent) => {
        let v: any = realValue
        //@ts-ignore
        if (isNaN(v))
            v = matheval(v)
        if (!Number.isNaN(v) && v !== false) {
            setRealValue(toCHF(fromCHF(realValue)))
            setNumberValid(true)
            v = fromCHF(v)
            if (v !== value)
                onChange({...e, target: {...e.target, value: v}})
        } else setNumberValid(false)
        setBlur(true)
    }

    useEffect(() => {
        setRealValue(toCHF(value))
    }, [value])


    return <input {...inputProps} value={realValue} onChange={e => setRealValue(e.target.value)}
                  onBlur={save} onAbort={save} onFocus={() => setBlur(false)}
        // @ts-ignore
                  onKeyDown={e => e.key === "Escape" && save(e)}
                  className={`${style.input} ${numberValid && valid ? "" : style.invalid} ${inputProps.className} ${blurStyle}`}/>
}

