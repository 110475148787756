import {useLocalStorage} from "../lib/storage";
import {BLUR_VALUES} from "../pages/settings/Settings";
// @ts-ignore
import style from "./BluredValue.module.scss";
import {useEffect, useState} from "react";

export const useBlur = () => {
    const [blur] = useLocalStorage<boolean>(BLUR_VALUES)
    const [blured, setBlured] = useState<boolean>(blur)

    useEffect(() => {
        if (blured === undefined)
            setTimeout(() => setBlured(true), 1000)
    }, [blured]);

    return [blured === true ? style.blur : style.noBlur, (t: boolean | undefined = undefined) => blur && setBlured(t === true ? true : (t === false ? false : undefined))]
}

export default function ({value, className = "", ...props}: {
    value: number | string,
    className?: string
}) {

    const [blurStyle, unBlur] = useBlur();

    return <p {...props} className={`${className} ${blurStyle}`}
              onClick={unBlur}>
        {value}
    </p>

}