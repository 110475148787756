import React, {useEffect, useState} from "react";
import EditableValue from "../../../components/form/EditableValue";
import {getJson, useApi} from "../../../lib/service";
import GroupSelector, {multiGroupId, multiGroupName} from "./GroupSelector";
import {Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip} from 'recharts';
import AggregatorSelector, {aggregate, label as aggregatorLabel} from "./AggregatorSelector";
import style from "./PieChartCell.module.scss"
import formStyle from "./CellForm.module.scss"


const RADIAN = Math.PI / 180;

const COLORS = [
    "#EB5E28", "#EB5E8C",
    "#EB36FF", "#A063FF",
    "#5B00FF", "#0000FF",
    "#00DDFF", "#00F6A0",
    "#00F653", "#C4FF22",
    "#FFFF00", "#E8D000"
]

const renderCustomizedLabel = ({cx, cy, midAngle, innerRadius, outerRadius, percent, index}) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};


const CustomTooltip = ({active, payload, label}) => {
    if (active && payload && payload.length && payload[0].name !== undefined)
        return <div className={style.tooltip}>
            <p>{payload[0].payload.ringName}</p>
            <p>{payload[0].payload.cellName}: {aggregatorLabel(payload[0].payload.aggregator, payload[0].value)}</p>
        </div>

    return null;
};

export default function ({config}) {

    const [transactions, setTransactions] = useState({})
    const [data, setData] = useState([])

    useApi(async () => {
        const transactions = {}
        for (const r of config.rings) {
            if (!transactions[r.query])
                transactions[r.query] = await getJson("/transactions", {query: r.query})
        }
        setTransactions(transactions)
    }, [config])


    useEffect(() => {
        const data = []
        for (const r of config.rings) {
            const ringData = []
            if (!transactions[r.query]) return;

            for (const t of transactions[r.query]) {
                const groupId = multiGroupId(r.groups.filter(g => !!g), t)
                const value = aggregate(r.aggregator, t)
                if (ringData.some(d => d.groupId === groupId)) {
                    ringData.find(d => d.groupId === groupId).value += value
                } else {
                    ringData.push({
                        groupId,
                        cellName: multiGroupName(r.groups.filter(g => !!g), t).join(", "),
                        ringName: r.name,
                        aggregator: r.aggregator,
                        sample: t,
                        value
                    })
                }
            }
            data.push({...r, data: ringData})
        }
        console.log("data", data)
        setData(data)
    }, [transactions]);

    const sectorColor = (r, e, i) => {
        if (r.groups.some(g => g === "category"))
            return e.sample.category.color
        return COLORS[i % COLORS.length];
    }

    const percentagePerRing = 90 / (config.rings.length + 1)

    return <div className={style.container}>
        <ResponsiveContainer width={"100%"} height={"100%"}>
            <PieChart>
                {data.map((d, i) => <Pie key={`ring-${i}`} data={d.data} dataKey="value" cx="50%" cy="50%"
                                         className={style.chart}
                                         stroke="#403D39" strokeWidth={3}
                                         innerRadius={(i === 0 ? 0 : i * percentagePerRing + percentagePerRing) + "%"}
                                         outerRadius={(i === 0 ? percentagePerRing * 2 : i * percentagePerRing + percentagePerRing + percentagePerRing) + "%"}
                                         labelLine={false} label={renderCustomizedLabel}>
                    {d.data.map((e, i) => (
                        <>
                            <Cell name={`${e.cellName} (${aggregatorLabel(e.aggregator, e.value)})`} key={`cell-${i}`}
                                  fill={sectorColor(d, e, i)}/>
                        </>
                    ))}
                </Pie>)}
                <Tooltip content={<CustomTooltip/>}/>
                <Legend/>
            </PieChart>
        </ResponsiveContainer>
    </div>
}


const DEFAULT_RING_CONFIG = {
    query: "",
    groups: [],
    aggregator: "amount",
    name: "root"
}
export const DEFAULT_CONFIG = {
    rings: [DEFAULT_RING_CONFIG]
}

export function Form({config, setConfig}) {

    return <>
        <div className={formStyle.formLabel}>
            <p>Rings</p>
            <span className="material-symbols-outlined" title={"Add Ring"}
                  onClick={() => setConfig({...config, rings: [...config.rings, DEFAULT_RING_CONFIG]})}>add</span>
        </div>

        {config.rings.map((r, i) => <div key={i} className={formStyle.formGroup}>
            <div className={formStyle.formLabel}>
                <p>Ring {i + 1}</p>
                <span className="material-symbols-outlined" title={"Remove Ring " + (i + 1)}
                      onClick={() => setConfig({
                          ...config,
                          rings: config.rings.filter((_, j) => i !== j)
                      })}>delete</span>
            </div>

            <div className={formStyle.formElement}>
                <label className={formStyle.formLabel}>Name</label>
                <EditableValue valid={false} value={r.name} onChange={e => setConfig({
                    ...config,
                    rings: config.rings.map(i => i === r ? {...r, name: e.target.value} : i)
                })} inputProps={{className: formStyle.formInput}}/>
            </div>

            <div className={formStyle.formElement}>
                <label className={formStyle.formLabel}>Query</label>
                <EditableValue valid={false} value={r.query} onChange={e => setConfig({
                    ...config,
                    rings: config.rings.map(i => i === r ? {...r, query: e.target.value} : i)
                })} inputProps={{className: formStyle.formInput}}/>
            </div>


            <GroupSelector groups={r.groups} setGroups={groups => setConfig({
                ...config,
                rings: config.rings.map(e => e === r ? {...r, groups} : e)
            })}/>

            <AggregatorSelector aggregator={r.aggregator} setAggregator={aggregator => setConfig({
                ...config,
                rings: config.rings.map(e => e === r ? {...r, aggregator} : e)
            })}/>
        </div>)}


    </>
}