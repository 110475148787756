import style from "./Transactions.module.scss";
import {formatDate, formatDateISO, millisFromISO, toCHF} from "../../lib/util";
import Modal from "../../components/Modal";
import React, {useEffect, useState} from "react";
import EditableValue from "../../components/form/EditableValue";
import Select, {Option} from "../../components/form/Select";
import CHFInput from "../../components/form/CHFInput";
import {TEMPLATE_ROW} from "./Transactions";
import TagSelect from "../../components/form/TagSelect";
import TagMultiSelect from "../../components/form/TagMultiSelect";
import Tag from "../../components/Tag";
import BluredValue from "../../components/BluredValue";

export default function ({
                             transactions,
                             updateTransaction,
                             deleteTransaction,
                             createTransaction,
                             selected,
                             setSelected,
                             tags,
                             pools
                         }) {
    const [edit, setEdit] = useState(false);

    useEffect(() => {
        if (!edit) return
        setEdit(transactions.find(t => t.id === edit.id))
    }, [transactions]);

    const save = () => {
        if (edit.id) updateTransaction(edit);
        else createTransaction(edit);
        setEdit(false)
    }

    return <>
        {transactions.map(t => <div key={t.id} className={style.transactionCard}>
            <input type={"checkbox"} onChange={e => e.target.checked ?
                setSelected([...selected, t]) : setSelected(selected.filter(i => i !== t))}
            />

            <div>
                <p>{formatDate(t.transactionDate)} {t.name} </p>
                {t.schedule && <i className={style.italic}>Scheduled by {t.schedule.name}</i>}
            </div>

            <p className={style.gridEnd}>{t.source.name} -> {t.destination.name}</p>

            <span className={`material-symbols-outlined ${style.edit}`} onClick={() => setEdit(t)}>edit</span>

            <p>
                {<Tag title={"Category"} className={style.bold} tag={t.category}/>}
                {(t.tags || []).map(t => <Tag key={t.id} tag={t}/>)}
            </p>

            <BluredValue className={style.gridEnd} value={toCHF(t.amount)}/>

        </div>)}

        <Modal title={(edit.id ? "Edit" : "Create") + " Transaction"} visible={!!edit}
               setVisible={() => setEdit(false)}>
            <div className={style.modal}>

                <label>Name</label>
                <div>
                    <EditableValue value={edit.name} onChange={e => {
                        setEdit({...edit, name: e.target.value})
                    }} inputProps={{required: true}}/>
                </div>

                <label>Date</label>
                <div>
                    <EditableValue value={formatDateISO(edit.transactionDate)} onChange={e => {
                        setEdit({...edit, transactionDate: millisFromISO(e.target.value)})
                    }} inputProps={{required: true, type: "date"}}/>
                </div>

                <label>Amount</label>
                <div>
                    <CHFInput value={edit.amount} onChange={e => {
                        setEdit({...edit, amount: e.target.value})
                    }} inputProps={{required: true}}/>
                </div>

                <label>Category</label>
                <div>
                    <TagSelect tags={tags} value={edit.category} onChange={(e, c) => {
                        setEdit({...edit, category: c})
                    }} required={true}/>
                </div>

                <label>Tags</label>
                <div>
                    <TagMultiSelect tags={tags.filter(t => t.id !== edit.category?.id)} selected={edit.tags || []}
                                    setSelected={t => {
                                        setEdit({...edit, tags: t || []})
                                    }}/>
                </div>

                <label>Source</label>
                <div>
                    <Select value={edit.source} onChange={(e, s) => {
                        setEdit({...edit, source: s})
                    }} required={true}>
                        {pools.map(p => <Option key={p.id} value={p}>{p.name}</Option>)}
                    </Select>
                </div>

                <label>Destination</label>
                <div>
                    <Select value={edit.destination} onChange={(e, s) => {
                        setEdit({...edit, destination: s})
                    }} required={true}>
                        {pools.map(p => <Option key={p.id} value={p}>{p.name}</Option>)}
                    </Select>
                </div>

                <div>
                    {edit.id && <button onClick={() => deleteTransaction(edit) && setEdit(false)}>Delete</button>}
                </div>
                <div className={style.gridEnd}>
                    <button onClick={save}>Save</button>
                </div>
            </div>
        </Modal>

        <div className={style.addBall} onClick={() => setEdit(TEMPLATE_ROW())}>
            <span className="material-symbols-outlined">add</span>
        </div>
    </>
}