@import "../../index";

:not(.input:focus):not(.input:invalid).input:not(.invalid) {
  border: none;
  background-color: inherit;
  color: $text-color;
}

.input[type=number] {
  -moz-appearance: textfield !important;
}