import React, {useEffect, useState} from "react";
import {TEMPLATE_ROW} from "../transactions/Transactions";
import style from "./Tags.module.scss";
import Modal from "../../components/Modal";
import EditableValue from "../../components/form/EditableValue";

export default function ({
                             tags,
                             selected,
                             setSelected,
                             updateTag,
                             createTag,
                             deleteTag,
                         }) {

    const [edit, setEdit] = useState(false);

    useEffect(() => {
        if (!edit) return
        setEdit(tags.find(t => t.id === edit.id))
    }, [tags]);

    const save = () => {
        if (edit.id) updateTag(edit);
        else createTag(edit);
        setEdit(false)
    }

    return <>
        {tags.map(t => <div key={t.id} className={style.tagCard}>
            <input type={"checkbox"} onChange={e => e.target.checked ?
                setSelected([...selected, t]) : setSelected(selected.filter(i => i !== t))}
            />
            <p>{t.name}</p>
            <p className={style.gridEnd} style={{color: t.color}}>{t.color}</p>

            <span className={`material-symbols-outlined ${style.edit}`} onClick={() => setEdit(t)}>edit</span>
        </div>)}

        <div className={style.addBallContainer}>
            <div className={style.addBall} onClick={() => setEdit(TEMPLATE_ROW())}>
                <span className="material-symbols-outlined">add</span>
            </div>
        </div>

        <Modal title={(edit.id ? "Edit" : "Create") + " Tag"} visible={!!edit} setVisible={() => setEdit(false)}>
            <div className={style.modal}>

                <label>Name</label>
                <div>
                    <EditableValue value={edit.name} onChange={e => {
                        setEdit({...edit, name: e.target.value})
                    }} inputProps={{required: true}}/>
                </div>
                <label>Color</label>
                <div>
                    <EditableValue value={edit.color} onChange={e => {
                        setEdit({...edit, color: e.target.value})
                    }} inputProps={{required: true, type: "color"}}/>
                </div>

                <div>
                    {edit.id && <button onClick={() => deleteTag(edit) && setEdit(false)}>Delete</button>}
                </div>
                <div className={style.gridEnd}>
                    <button onClick={save}>Save</button>
                </div>
            </div>
        </Modal>
    </>
}