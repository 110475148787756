import Table from "../../components/table/Table";
import style from "./Synthesise.module.scss";
import EditableValue from "../../components/form/EditableValue";
import Select, {Option} from "../../components/form/Select";
import CHFInput from "../../components/form/CHFInput";
import {useState} from "react";
import {PREFAB_TEMPLATE_ROW} from "./Synthesise";
import TagMultiSelect from "../../components/form/TagMultiSelect";
import TagSelect from "../../components/form/TagSelect";

const PREFAB_TABLE_HEADERS = ["Name", "Category", "Tags", "Source", "Destination", "Amount", "Actions"]
export default function ({
                             prefabs,
                             selectedPrefabs,
                             setSelectedPrefabs,
                             updatePrefab,
                             createPrefab,
                             deletePrefab,
                             tags,
                             pools
                         }) {

    const [empty, setEmpty] = useState(PREFAB_TEMPLATE_ROW());

    return <Table cols={PREFAB_TABLE_HEADERS} rows={[empty, ...prefabs]} className={style.prefabTable}
                  selected={selectedPrefabs} setSelected={setSelectedPrefabs}
                  row={r => <>
                      <td>
                          <EditableValue value={r.name} onChange={e => {
                              if (!r.id) setEmpty({...r, name: e.target.value})
                              else updatePrefab({...r, name: e.target.value})
                          }} inputProps={{required: true}}/>
                      </td>

                      <td>
                          <TagSelect tags={tags} value={r.category} onChange={(e, c) => {
                              if (!r.id) setEmpty({...r, category: c})
                              else updatePrefab({...r, category: c})
                          }}/>
                      </td>

                      <td>
                          <TagMultiSelect tags={tags.filter(t => t.id !== r.category?.id)} selected={r.tags || []}
                                          setSelected={t => {
                                              if (!r.id) setEmpty({...r, tags: t || []})
                                              else updatePrefab({...r, tags: t || []})
                                          }}/>
                      </td>

                      <td>
                          <Select value={r.source} onChange={(e, s) => {
                              if (!r.id) setEmpty({...r, source: s})
                              else updatePrefab({...r, source: s})
                          }}>
                              {pools.map(p => <Option key={p.id} value={p}>{p.name}</Option>)}
                          </Select>
                      </td>

                      <td>
                          <Select value={r.destination} onChange={(e, s) => {
                              if (!r.id) setEmpty({...r, destination: s})
                              else updatePrefab({...r, destination: s})
                          }}>
                              {pools.map(p => <Option key={p.id} value={p}>{p.name}</Option>)}
                          </Select>
                      </td>

                      <td>
                          <CHFInput value={r.amount} onChange={e => {
                              if (!r.id) setEmpty({...r, amount: e.target.value})
                              else updatePrefab({...r, amount: e.target.value})
                          }} inputProps={{required: true, className: style.amountInput}}/>
                      </td>

                      <td>
                          {r.id && <button onClick={() => deletePrefab(r)}>Delete</button>}
                          {!r.id &&
                              <button onClick={() => createPrefab(r) && setEmpty(PREFAB_TEMPLATE_ROW())}>Save</button>}
                      </td>
                  </>}/>
}