@import "../../index";

.toolbar {
  @extend .card;
  display: grid;
  grid-template-columns: auto 1fr;
}

.search {
  justify-self: end;
}

.table tr td {
  height: 4em;
}

.dateInput {
  width: auto;
}

.amountInput {
  max-width: 7em;
}

.schedule {
  font-size: 0.8em;
}

.transactionCard {
  @extend .card;
  display: grid;
  grid-template-columns: auto 1fr 1fr;
  align-items: start;
  grid-column-gap: 1em;
  grid-row-gap: 0.5em;
}

.edit {
  font-size: 1em;
}

.modal {
  padding-top: 1em;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-row-gap: 1em
}

.addBall {
  position: fixed;
  background-color: $accent-color;
  width: 3em;
  height: 3em;
  border-radius: 1.5em;
  right: 2em;
  bottom: 2em;
  display: grid;
  justify-content: center;
  align-content: center;
}

.queryErrorContainer {
  padding: 2em;
  font-size: larger;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.queryError {
  font-size: larger;
  white-space: pre;
  font-family: monospace;
  padding-top: 1em;
}