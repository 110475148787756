@import "../index";

.tag:enabled {
  color: black !important;
}

.tag:disabled {
  background-color: inherit !important;
}

