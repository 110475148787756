import Select, {Option} from "../../../components/form/Select";
import {DAYS, formatDate, MONTHS, weekOfYear} from "../../../lib/util";
// @ts-ignore
import formStyle from "./CellForm.module.scss"


const dateName = (d: Date) => DAYS[new Date(d).getDay()] + " " + formatDate(d)
const weekDayName = (d: Date) => DAYS[new Date(d).getDay()]
const yearOfWeekName = (d: Date) => "KW-" + weekOfYear(d) + " " + d.getFullYear()
const monthName = (d: Date) => MONTHS[d.getMonth()] + " " + d.getFullYear()


const TRANSACTION_GROUPS = {
    "name": {title: e => e.name, comparator: e => e.name},
    "day": {
        title: e => dateName(new Date(e.transactionDate)),
        comparator: e => e.transactionDate
    },
    "week": {
        title: e => yearOfWeekName(new Date(e.transactionDate)),
        comparator: e => yearOfWeekName(new Date(e.transactionDate))
    },
    "month": {
        title: e => monthName(new Date(e.transactionDate)),
        comparator: e => monthName(new Date(e.transactionDate))
    },
    "weekDay": {
        title: e => weekDayName(new Date(e.transactionDate)),
        comparator: e => weekDayName(new Date(e.transactionDate))
    },
    "category": {title: e => e.category.name, comparator: e => e.category.id},
    "schedule": {title: e => e.schedule?.name, comparator: e => e.schedule?.id},
    "prefab": {title: e => e.prefab?.name, comparator: e => e.prefab?.id},
    "source": {title: e => e.source.name, comparator: e => e.source.id},
    "destination": {title: e => e.destination.name, comparator: e => e.destination.id},
}

const ALL_TRANSACTION_GROUPS = [
    "name", "day", "week", "weekDay", "month", "category", "schedule", "prefab", "source", "destination"
]

export default function ({
                             groups,
                             setGroups,
                             allowedGroups = ALL_TRANSACTION_GROUPS,
                             allowMulti = true,
                             allowNull = false,
                             name = (allowMulti ? "Groups" : "Group")
                         }: {
    groups: string[],
    setGroups: (groups: string[]) => void
    allowedGroups: string[],
    allowMulti: boolean,
    allowNull: boolean,
    name: string
}) {


    groups = groups.length == 0 && !allowNull ? [null] : groups

    return <div className={formStyle.formElement}>
        <div className={formStyle.formLabel}>
            <label>{name}</label>
            {(allowMulti || groups.length == 0) && <span className="material-symbols-outlined" title={"Add group"}
                                                         onClick={() => setGroups([...groups, null])}>add</span>}
        </div>

        {groups.map((g, i) => <div key={i} className={formStyle.formInput}>

            <Select value={g} onChange={(e, v) =>
                setGroups(groups.map((o, j) => j === i ? v : o))
            } required={true}>
                {allowedGroups.map(e => <Option key={e} value={e}
                                                disabled={groups.some(e2 => e2 === e)}>{e}</Option>)}
            </Select>

            {(allowNull || groups.length > 1) && <span className="material-symbols-outlined" title={"remove"}
                                                       onClick={() => setGroups(groups.filter((_, j) => j !== i))}>delete</span>}
        </div>)}
    </div>
}


export const multiGroupId = (groups: string[], e: any): string => {
    let id = "";
    for (const g of groups)
        id += TRANSACTION_GROUPS[g].comparator(e)
    return id;
}

export const multiGroupName = (groups: string[], e: any): string[] => {
    let names = [];
    for (const g of groups)
        names.push(TRANSACTION_GROUPS[g].title(e))
    return names.length == 0 ? ["All"] : names;
}