import {atom, PrimitiveAtom, useAtom} from "jotai";
import {useEffect} from "react";

export type StorageKey<T> = {
    name: string,
    atom: PrimitiveAtom<T>
    default: T | undefined
}

export function storageKey<T>(name: string, value: T = undefined): StorageKey<T> {
    return {name, atom: atom<T>(undefined as T), default: value}
}

export function useLocalStorage<T>(key: StorageKey<T>): [T, (v: T) => void, () => void] {
    const load = () => {
        const item = localStorage.getItem(key.name);
        if (item === null) return key.default;
        try {
            return JSON.parse(item)
        } catch (e) {
            localStorage.removeItem(key.name)
            return key.default;
        }
    }

    const save = v => {
        localStorage.setItem(key.name, JSON.stringify(v))
    }

    const drop = () => {
        localStorage.removeItem(key.name)
        setState(undefined)
    }

    let [state, setState] = useAtom(key.atom)
    if (state === undefined) state = load();

    useEffect(() => {
        save(state)
    }, [state]);

    return [state, setState, drop]
}