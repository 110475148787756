import {SyntheticEvent, useEffect, useState} from "react";
// @ts-ignore
import style from "./EditableValue.module.scss"

export type ValuedChangeEvent = SyntheticEvent & { target: { value: string } }

export type EDITABLE_VALUE_PROPS = {
    value: string | number | undefined,
    onChange: (e: ValuedChangeEvent) => void,
    valid?: boolean
    input?: (p: any) => JSX.Element,
    inputProps?: any
}


export const DEFAULT_INPUT = (p: any) => <input {...p.props} value={p.value} onChange={p.onChange}
                                                onBlur={p.save} onAbort={p.save}
                                                onKeyDown={e => e.key === "Escape" && p.save(e)}
                                                className={`${style.input} ${p.valid ? "" : style.invalid} ${p.props.className}`}/>

export default function ({
                             value,
                             onChange = e => undefined,
                             valid = true,
                             input = DEFAULT_INPUT,
                             inputProps = {}
                         }: EDITABLE_VALUE_PROPS) {

    const [realValue, setRealValue] = useState(value);
    const save = (e: ValuedChangeEvent) => {
        value !== realValue && onChange(e)
        value !== realValue && setRealValue(value)
    }

    useEffect(() => {
        setRealValue(value)
    }, [value])

    return input({
        value: realValue,
        valid: valid,
        onChange: (e: ValuedChangeEvent) => setRealValue(e.target.value),
        save,
        props: inputProps
    })
}

