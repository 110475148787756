import Table from "../../components/table/Table";
import EditableValue from "../../components/form/EditableValue";
import Select, {Option} from "../../components/form/Select";
import {toCHF} from "../../lib/util";
import {POOL_TYPES, TEMPLATE_ROW} from "./Pools";
import {useState} from "react";
import BluredValue from "../../components/BluredValue";

const TABLE_HEADERS = ["Name", "Type", "Balance", "Actions"]

export default function ({
                             pools, selected, setSelected, updatePool, createPool, deletePool,
                         }) {

    const [empty, setEmpty] = useState(TEMPLATE_ROW());

    return <Table cols={TABLE_HEADERS} rows={[empty, ...pools]} selected={selected} setSelected={setSelected}
                  row={r => <>
                      <td>
                          <EditableValue value={r.name} onChange={e => {
                              if (!r.id) setEmpty({...r, name: e.target.value})
                              else updatePool({...r, name: e.target.value})
                          }} inputProps={{required: true}}/>
                      </td>
                      <td>
                          <Select value={r.type} onChange={(e, t) => {
                              if (!r.id) setEmpty({...r, type: t})
                              else updatePool({...r, type: t})
                          }} required={true}>
                              {POOL_TYPES.map(t => <Option key={t} value={t}>{t}</Option>)}
                          </Select>
                      </td>
                      <td><BluredValue value={toCHF(r.balance || 0)}/></td>
                      <td>
                          {r.id && <button onClick={() => deletePool(r)}>Delete</button>}
                          {!r.id && <button onClick={() => createPool(r) && setEmpty(TEMPLATE_ROW())}>Save</button>}
                      </td>
                  </>}/>
}