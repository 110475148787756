import Table from "../../components/table/Table";
import style from "./Overview.module.scss";
import {balancePrefix, formatDate, toCHF} from "../../lib/util";
import {poolTypeTitle} from "./Overview";
import Tag from "../../components/Tag";
import BluredValue from "../../components/BluredValue";

const TABLE_HEADERS = [
    {name: "Date", width: "20%"},
    {name: "Name", width: "15%"},
    {name: "Category", width: "17%"},
    {name: "Tags", width: "35%"},
    {name: "Amount", width: "16%"}
]

export default function ({transactions}) {

    return <Table cols={TABLE_HEADERS} rows={transactions} className={style.table}
                  selectable={false}
                  row={r => <>
                      <td><p>{formatDate(r.transactionDate)}</p></td>
                      <td><p>{r.name}</p></td>
                      <td>
                          <Tag tag={r.category}/>
                      </td>
                      <td>
                          {(r.tags || []).map(t => <Tag key={t.id} tag={t}/>)}
                      </td>
                      <td>
                          <BluredValue title={poolTypeTitle(r)} value={balancePrefix(r) + toCHF(r.amount)}/>
                      </td>
                  </>}>
    </Table>
}