import Select, {Option} from "../../../components/form/Select";
// @ts-ignore
import formStyle from "./CellForm.module.scss"
import {toCHF} from "../../../lib/util";


const TRANSACTION_AGGREGATORS = {
    "count": {aggregator: t => 1, label: v => v},
    "amount": {aggregator: t => t.amount, label: v => toCHF(v)},
}

const ALL_TRANSACTION_AGGREGATORS = [
    "count", "amount"
]

export default function ({
                             aggregator,
                             setAggregator,
                             allowedAggregators = ALL_TRANSACTION_AGGREGATORS,
                             name = "Aggregator"
                         }: {
    aggregator: string,
    setAggregator: (aggregator: string) => void,
    allowedAggregators: string[],
    name: string
}) {

    return <div className={formStyle.formElement}>
        <label className={formStyle.formLabel}>{name}</label>

        <Select value={aggregator} onChange={(e, v) => setAggregator(v)}
                required={true} className={formStyle.formInput}>
            {allowedAggregators.map(e => <Option key={e} value={e}>{e}</Option>)}
        </Select>
    </div>
}


export const aggregate = (aggregator: string, t: any): number => {
    return TRANSACTION_AGGREGATORS[aggregator].aggregator(t)
}
export const label = (aggregator: string, v: number): number => {
    return TRANSACTION_AGGREGATORS[aggregator].label(v)
}