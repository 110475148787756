export const DAYS = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

export const MONTHS = ["January", "February", "March", "April", "May", "June", "July",
    "August", "September", "October", "November", "December"];


export const formatDate = millis => {
    const date = new Date(millis)
    return fill(date.getDate(), 2, {start: "0"}) + "." + fill(date.getMonth() + 1, 2, {start: "0"}) + "." + date.getFullYear()
}

//millis -> 2023-12-32
export const formatDateISO = millis => {
    const date = new Date(millis)
    return date.getFullYear() + "-" + fill(date.getMonth() + 1, 2, {start: "0"}) + "-" + fill(date.getDate(), 2, {start: "0"})
}

//2023-12-32 -> millis
export const millisFromISO = iso => new Date(iso).valueOf();

export const formatTime = millis => {
    const date = new Date(millis)
    return fill(date.getHours(), 2, {start: "0"}) + ":" + fill(date.getMinutes(), 2, {start: "0"})
}

export const formatDateTime = millis => formatDate(millis) + " " + formatTime(millis)


export const DEFAULT_DATE_FORMAT = /(?<d>\d{1,2})\.(?<m>\d{1,2})\.(?<y>\d{4})/
export const millisFromDate = (regex: RegExp, date: string) => {
    const groups = regex.exec(date).groups
    return new Date(`${groups.y}-${groups.m}-${groups.d}`).valueOf();
}


export const toCHF = v => (v / 100).toFixed(2);
export const fromCHF = v => Math.round(v * 100);

export const fill = (txt, length, {start = "", end = ""}) => {
    txt = String(txt)
    while (txt.length < length) {
        txt = start + txt + end;
    }
    return txt;
}


export const weekOfYear = d => {
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    const yearStart = Date.UTC(d.getUTCFullYear(), 0, 1);
    return Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
}


export const balancePrefix = t => {
    const src = t.source.type
    const dst = t.destination.type
    if (src === "BALANCE" && dst === "EXPENSE")
        return "-"
    if (src === "EXPENSE" && dst === "BALANCE")
        return "+"
    return ""
}

export const cleanCopyDate = (d: number | string | Date) => {
    const date = new Date(d);
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)
    return date;
}