import style from "./Tags.module.scss";
import {useState} from "react";
import {_delete, getJson, postJson, putJson, useApi} from "../../lib/service";
import toastr from "../../lib/toastr";
import MediaQuery from "react-responsive";
import TagsDesktop from "./TagsDesktop";
import TagsMobile from "./TagsMobile";

export const TEMPLATE_ROW = () => ({
    name: "", color: "#ffffff"
})

const validateTag = t => {
    return t.name && t.color;
}

export default function () {

    const [tags, setTags] = useState([]);
    const [selected, setSelected] = useState([])

    useApi(async () => {
        setTags(await getJson("/tags"))
    }, []);

    const createTag = async t => {
        if (!validateTag(t)) return;
        try {
            t = await postJson("/tags", {
                name: t.name, color: t.color
            })
            setTags([t, ...tags])
            toastr.success("Created Tag")
        } catch (e) {
            toastr.warning("Create Failed")
        }
    }

    const updateTag = async t => {
        if (!validateTag(t)) return
        try {
            t = await putJson("/tags/" + t.id, {
                name: t.name, color: t.color
            })
            setTags(tags.map(i => i.id === t.id ? t : i))
            toastr.success("Updated Tag")
        } catch (e) {
            toastr.warning("Update Failed")
        }
    }

    const deleteTag = async t => {
        try {
            await _delete("/tags/" + t.id)
            setTags(tags.filter(i => i !== t))
            setSelected(selected.filter(i => i !== t))
            toastr.success("Deleted Tag")
        } catch (e) {
            toastr.warning("Deleted Failed")
        }
    }

    const deleteSelected = async () => {
        if (!confirm("Delete all selected " + selected.length + " Tags?")) return;
        const errors = []
        for (const t of selected)
            await _delete("/tags/" + t.id).catch(e => errors.push(t))
        setTags(tags.filter(i => !selected.includes(i) || errors.includes(i)))
        setSelected([])
        if (errors.length === 0) toastr.success("Deleted " + selected.length + " Tags")
        else toastr.warning("Delete Failed, deleted " + (selected.length - errors.length) + " of " + selected.length + " Tags")
    }

    return (
        <div>

            <div className={style.sectionHead}>
                <h1>Tags</h1>
            </div>

            <div className={style.toolbar}>
                <button disabled={!selected.length} onClick={deleteSelected}>Delete</button>

                <div className={style.search}>
                    <input placeholder={"search"}/>
                </div>
            </div>

            <MediaQuery minDeviceWidth={1200}>
                <TagsDesktop tags={tags}
                             selected={selected} setSelected={setSelected}
                             updateTag={updateTag} createTag={createTag} deleteTag={deleteTag}/>
            </MediaQuery>

            <MediaQuery maxDeviceWidth={1200}>
                <TagsMobile tags={tags}
                            selected={selected} setSelected={setSelected}
                            updateTag={updateTag} createTag={createTag} deleteTag={deleteTag}/>
            </MediaQuery>

        </div>
    );
}
