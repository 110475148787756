import {Option, SELECT_PROPS} from "./Select";
import EditableSelect from "./EditableSelect";
// @ts-ignore
import style from "./TagSelect.module.scss";

export type Tag = { id: number, name: string, color: string }

export type TAG_SELECT_PROPS = {
    tags: Tag[]
} & SELECT_PROPS<Tag>

export const tagSort = (e1, e2) => e1.name.localeCompare(e2.name)


export default function ({tags, value, className = "", ...props}: TAG_SELECT_PROPS) {

    return (
        <EditableSelect {...props} value={value} className={`${className} ${style.select}`}
                        style={{color: value?.color}}>
            {tags.sort(tagSort).map(t => <Option key={t.id} value={t}>{t.name}</Option>)}
        </EditableSelect>
    )

}