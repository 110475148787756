import style from "./Pools.module.scss";
import {useState} from "react";
import {_delete, getJson, postJson, putJson, useApi} from "../../lib/service";
import toastr from "../../lib/toastr";
import MediaQuery from "react-responsive";
import PoolsDesktop from "./PoolsDesktop";
import PoolsMobile from "./PoolsMobile";

export const POOL_TYPES = ["EXPENSE", "BALANCE", "NONE"]

export const TEMPLATE_ROW = () => ({
    name: "", type: undefined, balance: 0
})

const validatePool = t => {
    return t.name && t.type;
}

export default function () {

    const [pools, setPools] = useState([]);
    const [selected, setSelected] = useState([])

    useApi(async () => {
        setPools(await getJson("/pools"))
    }, []);

    const createPool = async p => {
        if (!validatePool(p)) return;
        try {
            p = await postJson("/pools", {
                name: p.name, type: p.type
            })
            setPools([p, ...pools])
            toastr.success("Created Pool")
        } catch (e) {
            toastr.warning("Create Failed")
        }
    }

    const updatePool = async p => {
        if (!validatePool(p)) return
        try {
            p = await putJson("/pools/" + p.id, {
                name: p.name, type: p.type
            })
            setPools(pools.map(i => i.id === p.id ? p : i))
            toastr.success("Updated Pool")
        } catch (e) {
            toastr.warning("Update Failed")
        }
    }

    const deletePool = async p => {
        try {
            await _delete("/pools/" + p.id)
            setPools(pools.filter(i => i !== p))
            setSelected(selected.filter(i => i !== p))
            toastr.success("Deleted Pool")
        } catch (e) {
            toastr.warning("Deleted Failed")
        }
    }

    const deleteSelected = async () => {
        if (!confirm("Delete all selected (" + selected.length + ") Pools?")) return;
        const errors = []
        for (const p of selected)
            await _delete("/pools/" + p.id).catch(e => errors.push(p))
        setPools(pools.filter(i => !selected.includes(i) || errors.includes(i)))
        setSelected([])
        if (errors.length === 0) toastr.success("Deleted " + selected.length + " Pools")
        else toastr.warning("Delete Failed, deleted " + (selected.length - errors.length) + " of " + selected.length + " Pools")
    }

    return (
        <div>

            <div className={style.sectionHead}>
                <h1>Pools</h1>
            </div>

            <div className={style.toolbar}>
                <button disabled={!selected.length} onClick={deleteSelected}>Delete</button>

                <div className={style.search}>
                    <input placeholder={"search"}/>
                </div>
            </div>

            <MediaQuery minDeviceWidth={1200}>
                <PoolsDesktop pools={pools}
                              selected={selected} setSelected={setSelected}
                              updatePool={updatePool} createPool={createPool} deletePool={deletePool}/>
            </MediaQuery>

            <MediaQuery maxDeviceWidth={1200}>
                <PoolsMobile pools={pools}
                             selected={selected} setSelected={setSelected}
                             updatePool={updatePool} createPool={createPool} deletePool={deletePool}/>
            </MediaQuery>

        </div>
    );
}
