import appStyle from "../../App.module.scss";
import GridLayout, {Cell} from "../../components/GridLayout";
import React, {useState} from "react";
import {storageKey, useLocalStorage} from "../../lib/storage";
import style from "./Dashboard.module.scss";
import {CELL_TYPES} from "./DashboardCellForm";
import {NavLink} from "react-router-dom";


export const DEFAULT_LAYOUT = [
    {"key": "689", "x": 0, "y": 0, "w": 6, "h": 5},
    {"key": "453", "x": 6, "y": 0, "w": 6, "h": 5},
    {"key": "173", "x": 7, "y": 5, "w": 5, "h": 6},
    {"key": "436", "x": 0, "y": 5, "w": 7, "h": 6}
]

export const DEFAULT_CELL_CONFIG = {
    "173": {
        "groups": ["category"],
        "aggregator": "count",
        "layers": [{"query": "", "name": "root"}],
        "name": "Amount of Transactions per Category",
        "type": "spider"
    },
    "436": {
        "query": "tag.name not-contains \"in\"",
        "aggregator": "All (-Expense, +Balance)",
        "groups": ["day"],
        "name": "Expenses per day",
        "type": "list"
    },
    "453": {
        "query": "tag.name contains \"beer\" && age.day < 30",
        "groups": [],
        "name": "Beer last 30 days",
        "type": "list"
    },
    "689": {
        "rings": [{
            "query": "tag.name not-contains \"in\"",
            "groups": ["category"],
            "aggregator": "amount",
            "name": ""
        }], "name": "Pie Chart by Categories", "type": "pie"
    }
}

export const LAYOUT = storageKey("dashboardLayout", DEFAULT_LAYOUT)
export const CELL_CONFIG = storageKey("dashboardCellConfig", DEFAULT_CELL_CONFIG)


export const useLayout = () => {

    const [layout, setLayout] = useLocalStorage(LAYOUT)
    const [cellConfig, setCellConfig] = useLocalStorage(CELL_CONFIG)

    const addCell = (config) => {
        const key = Math.round(Math.random() * 10000);
        setLayout([...layout, {key, x: 0, y: 0, w: 1, h: 1}])
        setCellConfig({...cellConfig, [key]: config})
    }

    const editCell = (id, config) => {
        setCellConfig({...cellConfig, [id]: config})
    }

    const removeCell = (id) => {
        setLayout(layout.filter(l => l.key !== id))
    }

    return {
        addCell,
        editCell,
        removeCell,
        layout,
        setLayout,
        cellConfig
    }
}

const renderCell = (Cell, config) => {
    return <Cell config={config}></Cell>
}

export default function () {

    const {layout, setLayout, cellConfig} = useLayout()
    const [edit, setEdit] = useState(false)

    return <div>
        <style>
            {`.${appStyle.content} {
                max-width: 100vw
             }`
            }
        </style>

        <div className={style.sectionHead}>
            <h1>Dashboard</h1>
            {edit || <span onClick={() => setEdit(true)}
                           className={`material-symbols-outlined ${style.clickable}`}>edit</span>}
            {edit && <span onClick={() => setEdit(false)}
                           className={`material-symbols-outlined ${style.clickable}`}>close</span>}
        </div>

        <GridLayout margin={{x: 20, y: 20}} resizable={edit} draggable={edit} onLayoutChange={setLayout}
                    dragHandleClassName={"mDragHandle"}>
            {layout.map(l =>
                <Cell layout={{...l, minW: 3, minH: 2}} key={l.key}>
                    <div className={style.cell}>
                        <div className={style.cellHeader}>
                            <h2>{cellConfig[l.key].name}</h2>
                            {edit && <>
                                <div className={style.justifyEnd}>
                                    <span
                                        className={`material-symbols-outlined ${style.clickable} mDragHandle`}>open_with</span>
                                </div>
                                <NavLink to={"/dashboard/edit/?id=" + l.key} className={style.justifyEnd}>
                                    <span className={`material-symbols-outlined ${style.clickable}`}>edit</span>
                                </NavLink>
                            </>}
                        </div>

                        <div className={style.cellContent}>
                            {renderCell(CELL_TYPES[cellConfig[l.key].type].cell, cellConfig[l.key])}
                        </div>
                    </div>
                </Cell>
            )}
        </GridLayout>

        {edit &&
            <NavLink to={"/dashboard/edit"}>
                <div className={style.addBall}>
                    <span className="material-symbols-outlined">add</span>
                </div>
            </NavLink>
        }

    </div>
}

