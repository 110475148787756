import {Route as ReactRout, Routes} from "react-router-dom";

export type Route = {
    page: any,
    path: string
}

export type RouteConfig = {
    routes: Route[],
}

export function useRoute(routes: Route[]): RouteConfig {
    return {routes}
}

export default function (props: { route: RouteConfig }) {
    return (
        <Routes>
            {props.route.routes.map(r => <ReactRout key={r.path} path={r.path} element={
                <r.page/>
            }/>)}
        </Routes>
    );
}
