import 'react-js-cron/dist/styles.css'
import {SyntheticEvent} from "react";
import EditableValue from "./EditableValue";
// @ts-ignore
import style from "./CronInput.module.scss"


function isValidScheduleExpression(expression: string): boolean {
    return /^(\*|[0-9]+) (\*|-?[0-9]+|[a-zA-Z]+) (\*|-?[0-9]+|[a-zA-Z]+)$/.test(expression);
}

export type ValuedChangeEvent = SyntheticEvent & {
    target: {
        value: string
    }
}

export type CRON_INPUT_PROPS = {
    value: string
    onChange: (e: ValuedChangeEvent) => void
    className?: string
}

export default function ({value, onChange, className = ""}: CRON_INPUT_PROPS) {

    return <>
        <div className={style.container}>
            <EditableValue value={value} valid={value && isValidScheduleExpression(value)} onChange={e => {
                onChange(e)
            }} inputProps={{className}}/>
        </div>
    </>

}