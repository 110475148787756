import style from "./Synthesise.module.scss";
import {useState} from "react";
import {_delete, getJson, postJson, putJson, useApi} from "../../lib/service";
import toastr from "../../lib/toastr";
import PrefabsDesktop from "./PrefabsDesktop";
import MediaQuery from "react-responsive";
import PrefabsMobile from "./PrefabsMobile";
import SchedulesDesktop from "./SchedulesDesktop";
import SchedulesMobile from "./SchedulesMobile";

export const CRON_OPTIONS = {
    allowedDropdowns: [
        'period',
        'months',
        'month-days',
        'week-days',
    ],
    allowedPeriods: [
        'year',
        'month',
        'week',
        'day',
        'reboot',
    ],
}

export const PREFAB_TEMPLATE_ROW = () => ({
    name: "", category: undefined, source: undefined,
    destination: undefined, schedule: null,
    prefab: null, amount: "0", tags: [],
    transactionDate: Date.now()
})

export const SCHEDULE_TEMPLATE_ROW = () => ({
    name: "", prefab: undefined, cron: "0 0 * * *", start: Date.now(), end: undefined,
})

const validatePrefab = p => {
    return true
}

const validateSchedule = s => {
    return s.name && s.prefab && s.cron && s.start;
}

const isSchedulablePrefab = p => {
    return p.name && p.category && p.source && p.destination && p.amount
}

export default function () {

    const [prefabs, setPrefabs] = useState([]);
    const [schedules, setSchedules] = useState([]);
    const [tags, setTags] = useState([]);
    const [pools, setPools] = useState([]);

    const [selectedPrefabs, setSelectedPrefabs] = useState([])
    const [selectedSchedules, setSelectedSchedules] = useState([])

    useApi(async () => {
        setPrefabs(await getJson("/prefabs"))
        setSchedules(await getJson("/schedules"))
        setTags(await getJson("/tags"))
        setPools(await getJson("/pools"))
    }, []);

    const createPrefab = async p => {
        if (!validatePrefab(p)) return;
        try {
            const prefab = await postJson("/prefabs", {
                name: p.name,
                amount: p.amount,
                category: p.category?.id,
                source: p.source?.id,
                destination: p.destination?.id,
                tags: p.tags?.map(i => i.id) || []
            })
            setPrefabs([prefab, ...prefabs])
            toastr.success("Created Prefab")
        } catch (e) {
            toastr.warning("Create Failed")
        }

    }

    const updatePrefab = async p => {
        if (!validatePrefab(p)) return
        try {
            p = await putJson("/prefabs/" + p.id, {
                name: p.name, amount: p.amount,
                category: p.category?.id,
                source: p.source?.id,
                destination: p.destination?.id,
                tags: p.tags?.map(i => i.id) || []
            })
            setPrefabs(prefabs.map(i => i.id === p.id ? p : i))
            toastr.success("Updated Prefab")
        } catch (e) {
            toastr.warning("Update Failed")
        }
    }

    const deletePrefab = async p => {
        try {
            await _delete("/prefabs/" + p.id)
            setPrefabs(prefabs.filter(i => i !== p))
            setSelectedPrefabs(selectedPrefabs.filter(i => i !== p))
            toastr.success("Deleted Prefab")
        } catch (e) {
            toastr.warning("Deleted Failed")
        }
    }

    const deleteSelectedPrefabs = async () => {
        if (!confirm("Delete all selected (" + selectedPrefabs.length + ") Prefabs?")) return;
        const errors = []
        for (const p of selectedPrefabs)
            await _delete("/prefabs/" + p.id).catch(e => errors.push(p))
        setPrefabs(prefabs.filter(i => !selectedPrefabs.includes(i) || errors.includes(i)))
        setSelectedPrefabs([])
        if (errors.length === 0) toastr.success("Deleted " + selectedPrefabs.length + " Prefabs")
        else toastr.warning("Delete Failed, deleted " + (selectedPrefabs.length - errors.length) + " of " + selectedPrefabs.length + " Prefabs")
    }

    const createSchedule = async s => {
        if (!validateSchedule(s)) return;
        try {
            s = await postJson("/schedules", {
                name: s.name, cron: s.cron, start: s.start, end: s.end,
                prefab: s.prefab.id,
            })
            setSchedules([s, ...schedules])
            toastr.success("Created Schedule")
        } catch (e) {
            toastr.warning("Create Failed")
        }
    }

    const updateSchedule = async s => {
        if (!validateSchedule(s)) return
        try {
            s = await putJson("/schedules/" + s.id, {
                name: s.name, cron: s.cron, start: s.start, end: s.end,
                prefab: s.prefab.id,
            })
            setSchedules(schedules.map(i => i.id === s.id ? s : i))
            toastr.success("Updated Schedule")
        } catch (e) {
            toastr.warning("Update Failed")
        }
    }

    const enableSchedule = async s => {
        try {
            s = await putJson("/schedules/" + s.id + "/enable")
            setSchedules(schedules.map(i => i.id === s.id ? s : i))
            toastr.success("Enabled Schedule")
        } catch (e) {
            toastr.warning("Enable Failed")
        }
    }

    const disableSchedule = async s => {
        try {
            s = await putJson("/schedules/" + s.id + "/disable")
            setSchedules(schedules.map(i => i.id === s.id ? s : i))
            toastr.success("Disabled Schedule")
        } catch (e) {
            toastr.warning("Disabled Failed")
        }
    }

    const deleteSchedule = async s => {
        try {
            await _delete("/schedules/" + s.id)
            setSchedules(schedules.filter(i => i !== s))
            setSelectedSchedules(selectedSchedules.filter(i => i !== s))
            toastr.success("Deleted Schedule")
        } catch (e) {
            toastr.warning("Deleted Failed")
        }
    }

    const deleteSelectedSchedules = async () => {
        if (!confirm("Delete all selected (" + selectedSchedules.length + ") Schedules?")) return;
        const errors = []
        for (const s of selectedSchedules)
            await _delete("/schedules/" + s.id).catch(e => errors.push(s))
        setSchedules(prefabs.filter(i => !selectedSchedules.includes(i) || errors.includes(i)))
        setSelectedSchedules([])
        if (errors.length === 0) toastr.success("Deleted " + selectedSchedules.length + " Schedules")
        else toastr.warning("Delete Failed, deleted " + (selectedSchedules.length - errors.length) + " of " + selectedSchedules.length + " Schedules")
    }

    return (
        <div>
            <div className={style.sectionHead}>
                <h1>Prefabs</h1>
            </div>

            <div className={style.toolbar}>
                <button disabled={!selectedPrefabs.length} onClick={deleteSelectedPrefabs}>Delete</button>

                <div className={style.search}>
                    <input placeholder={"search"}/>
                </div>
            </div>

            <MediaQuery minDeviceWidth={1200}>
                <PrefabsDesktop prefabs={prefabs}
                                selectedPrefabs={selectedPrefabs} setSelectedPrefabs={setSelectedPrefabs}
                                updatePrefab={updatePrefab} createPrefab={createPrefab} deletePrefab={deletePrefab}
                                tags={tags} pools={pools}/>
            </MediaQuery>

            <MediaQuery maxDeviceWidth={1200}>
                <PrefabsMobile prefabs={prefabs}
                               selectedPrefabs={selectedPrefabs} setSelectedPrefabs={setSelectedPrefabs}
                               updatePrefab={updatePrefab} createPrefab={createPrefab} deletePrefab={deletePrefab}
                               tags={tags} pools={pools}/>
            </MediaQuery>


            <div className={style.sectionHead}>
                <h1>Schedules</h1>
            </div>

            <div className={style.toolbar}>
                <button disabled={!selectedSchedules.length} onClick={deleteSelectedSchedules}>Delete</button>

                <div className={style.search}>
                    <input placeholder={"search"}/>
                </div>
            </div>

            <MediaQuery minDeviceWidth={1200}>
                <SchedulesDesktop schedules={schedules}
                                  selectedSchedules={selectedSchedules} setSelectedSchedules={setSelectedSchedules}
                                  updateSchedule={updateSchedule} createSchedule={createSchedule}
                                  deleteSchedule={deleteSchedule}
                                  disableSchedule={disableSchedule} enableSchedule={enableSchedule}
                                  prefabs={prefabs.filter(p => p.id && isSchedulablePrefab(p))}/>
            </MediaQuery>

            <MediaQuery maxDeviceWidth={1200}>
                <SchedulesMobile schedules={schedules}
                                 selectedSchedules={selectedSchedules} setSelectedSchedules={setSelectedSchedules}
                                 updateSchedule={updateSchedule} createSchedule={createSchedule}
                                 deleteSchedule={deleteSchedule}
                                 disableSchedule={disableSchedule} enableSchedule={enableSchedule}
                                 prefabs={prefabs.filter(p => p.id && isSchedulablePrefab(p))}/>
            </MediaQuery>


        </div>
    );
}
