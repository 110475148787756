import React, {useEffect, useState} from "react";
import {getJson, useApi} from "../../../lib/service";
import GroupSelector, {multiGroupName} from "./GroupSelector";
import AggregatorSelector, {aggregate, label as aggregatorLabel} from "./AggregatorSelector";
import style from "./SpiderChartCell.module.scss"
import formStyle from "./CellForm.module.scss";
import EditableValue from "../../../components/form/EditableValue";
import {
    Legend,
    PolarAngleAxis,
    PolarGrid,
    PolarRadiusAxis,
    Radar,
    RadarChart,
    ResponsiveContainer,
    Tooltip
} from "recharts";


const COLORS = [
    "#EB5E28", "#EB5E8C",
    "#EB36FF", "#A063FF",
    "#5B00FF", "#0000FF",
    "#00DDFF", "#00F6A0",
    "#00F653", "#C4FF22",
    "#FFFF00", "#E8D000"
]

const CustomTooltip = ({active, payload, label}) => {
    if (active && payload && payload.length && payload[0].name !== undefined && payload[0].payload.aggregator)
        return <div className={style.tooltip}>
            <p>{payload[0].name}</p>
            <p>{payload[0].payload.group}: {aggregatorLabel(payload[0].payload.aggregator, payload[0].value)}</p>
        </div>
    return null;
};

export default function ({config}) {

    const [transactions, setTransactions] = useState({})
    const [data, setData] = useState([])

    useApi(async () => {
        const transactions = {}
        for (const l of config.layers) {
            if (!transactions[l.query])
                transactions[l.query] = await getJson("/transactions", {query: l.query})
        }
        setTransactions(transactions)
    }, [config])


    useEffect(() => {
        const data = []
        for (const l of config.layers) {
            if (!transactions[l.query]) return;
            for (const t of transactions[l.query]) {
                const group = multiGroupName(config.groups.filter(g => !!g), t).join(", ")
                if (!data.some(d => d.group === group))
                    data.push({group})
            }
        }
        let i = 0;
        for (const l of config.layers) {
            for (const t of transactions[l.query]) {
                const group = multiGroupName(config.groups.filter(g => !!g), t).join(", ")

                const value = aggregate(config.aggregator, t)
                const axis = data.find(d => d.group === group);

                if (axis["data" + i]) {
                    axis["data" + i] += value
                    axis["aggregator"] = config.aggregator
                } else {
                    axis["data" + i] = value
                }
            }
            i++;
        }
        setData(data)
    }, [transactions]);

    return <div className={style.container}>
        <ResponsiveContainer width={"100%"} height={"100%"}>
            <RadarChart cx="50%" cy="50%" outerRadius="60%" data={data}>
                <PolarGrid/>
                <PolarAngleAxis dataKey="group"/>
                <PolarRadiusAxis angle={30}/>

                {config.layers.map((l, i) =>
                    <Radar name={l.name} dataKey={"data" + i} stroke={COLORS[i % COLORS.length]}
                           fill={COLORS[i % COLORS.length]} fillOpacity={0.4}/>
                )}
                <Legend/>
                <Tooltip content={<CustomTooltip/>}/>
            </RadarChart>
        </ResponsiveContainer>
    </div>
}

const DEFAULT_LAYER_CONFIG = {
    query: "",
    name: "root"
}
export const DEFAULT_CONFIG = {
    groups: [],
    aggregator: "amount",
    layers: [DEFAULT_LAYER_CONFIG]
}

export function Form({config, setConfig}) {

    return <>

        <AggregatorSelector aggregator={config.aggregator}
                            setAggregator={aggregator => setConfig({...config, aggregator})}/>

        <GroupSelector groups={config.groups} setGroups={groups => setConfig({
            ...config, groups
        })}/>

        <div className={formStyle.formLabel}>
            <p>Layers</p>
            <span className="material-symbols-outlined" title={"Add Layer"}
                  onClick={() => setConfig({...config, layers: [...config.layers, DEFAULT_LAYER_CONFIG]})}>add</span>
        </div>

        {config.layers.map((l, i) => <div key={i} className={formStyle.formGroup}>
            <div className={formStyle.formLabel}>
                <p>Layer {i + 1}</p>
                <span className="material-symbols-outlined" title={"Remove Ring " + (i + 1)}
                      onClick={() => setConfig({
                          ...config,
                          layers: config.layers.filter((_, j) => i !== j)
                      })}>delete</span>
            </div>

            <div className={formStyle.formElement}>
                <label className={formStyle.formLabel}>Query</label>
                <EditableValue valid={false} value={l.query} onChange={e => setConfig({
                    ...config,
                    layers: config.layers.map(i => i === l ? {...l, query: e.target.value} : i)
                })} inputProps={{className: formStyle.formInput}}/>
            </div>


            <div className={formStyle.formElement}>
                <label className={formStyle.formLabel}>Name</label>
                <EditableValue valid={false} value={l.name} onChange={e => setConfig({
                    ...config,
                    layers: config.layers.map(i => i === l ? {...l, name: e.target.value} : i)
                })} inputProps={{className: formStyle.formInput}}/>
            </div>


        </div>)}
    </>
}