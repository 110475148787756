import style from "./Overview.module.scss";
import {balancePrefix, formatDate, toCHF} from "../../lib/util";
import {poolTypeTitle} from "./Overview";
import Tag from "../../components/Tag";
import React from "react";
import BluredValue from "../../components/BluredValue";

export default function ({transactions}) {
    return <div>
        {transactions.map(t => <div key={t.id} className={style.transactionCard}>
            <p>{t.name}</p>
            <p>
                <Tag title={"Category"} className={style.bold} tag={t.category}/>
                {(t.tags || []).map(t => <Tag key={t.id} tag={t}/>)}
            </p>

            <p>{formatDate(t.transactionDate)}</p>
            <BluredValue title={poolTypeTitle(t)} value={balancePrefix(t) + toCHF(t.amount)}/>
        </div>)}
    </div>
}