@import "../../index";

.toolbar {
  @extend .card;
}

.cardHeader {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  padding: 0 .5em .5em 0;
}

.table tr td {
  border-top-color: $primary-color;
}

.transactionCard {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: $primary-color;
  padding: 0.5em 0.5em 0.5em 0;
  grid-row-gap: 0.5em
}

.transactionCard *:nth-child(even) {
  justify-self: end;
}

.transactionCard:first-child {
  border-top-color: $accent-color;
}