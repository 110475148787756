@import "../../../index";

.tooltip {
  background-color: $secondary-color;
  padding: 4px 3px 1px 3px;
  border-radius: 3px;
  opacity: 0.9;
}

.chart {
  background-color: $primary-color;
}

.container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}