import style from "./Settings.module.scss";
import {storageKey, useLocalStorage} from "../../lib/storage";
import {patch} from "../../lib/service";
import toastr from "../../lib/toastr";
import {CELL_CONFIG, DEFAULT_CELL_CONFIG, DEFAULT_LAYOUT, LAYOUT} from "../dashboard/Dashboard";


export const BLUR_VALUES = storageKey("blurValues", false)

export default function () {

    const [blurVales, setBlurValues, dropBlurValues] = useLocalStorage(BLUR_VALUES)
    const [layout, setLayout] = useLocalStorage(LAYOUT)
    const [cellConfig, setCellConfig] = useLocalStorage(CELL_CONFIG)

    const reset = () => {
        dropBlurValues()
    }

    const resetDatabase = async () => {
        try {
            await patch("/demo/reset")
            setLayout(DEFAULT_LAYOUT)
            setCellConfig(DEFAULT_CELL_CONFIG)
            toastr.success("Successfully reset database")
        } catch (e) {
            console.log(e)
            toastr.warning("Reset failed")
        }
    }

    return (
        <div>
            <div className={style.sectionHead}>
                <h1>Prefabs</h1>
            </div>

            <div className={style.infoCard}>
                <span className="material-symbols-outlined">info</span>
                <div>
                    <p className={style.bold}>Settings are saved in LocalStorage</p>
                    <p>It will not persist on different devices or if the browser cache is cleared</p>
                </div>
            </div>

            <div className={style.entry}>
                <label>Blur Values</label>
                <input checked={blurVales} onChange={e => setBlurValues(e.target.checked)} className={style.gridEnd}
                       type={"checkbox"}/>
            </div>


            <div className={style.entry}>
                <label>Reset to defaults</label>
                <button className={style.gridEnd} onClick={reset}>Reset</button>
            </div>

            <div className={style.entry}>
                <label>Reset database</label>
                <button className={style.gridEnd} onClick={resetDatabase}>Reset</button>
            </div>
        </div>
    )
}