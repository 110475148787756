import React, {useEffect, useState} from "react";
import style from "./Synthesise.module.scss";
import {formatDate, formatDateISO, millisFromISO} from "../../lib/util";
import Modal from "../../components/Modal";
import EditableValue from "../../components/form/EditableValue";
import Select, {Option} from "../../components/form/Select";
import CronInput from "../../components/form/CronInput";
import {CRON_OPTIONS, SCHEDULE_TEMPLATE_ROW} from "./Synthesise";

export default function ({
                             schedules,
                             selectedSchedules,
                             setSelectedSchedules,
                             updateSchedule,
                             createSchedule,
                             deleteSchedule,
                             disableSchedule,
                             enableSchedule,
                             prefabs
                         }) {

    const [edit, setEdit] = useState(false);

    useEffect(() => {
        if (!edit) return
        setEdit(schedules.find(s => s.id === edit.id))
    }, [schedules]);

    const save = () => {
        if (edit.id) updateSchedule(edit);
        else createSchedule(edit);
        setEdit(false)
    }

    return <>
        {schedules.map(s => <div key={s.id} className={style.scheduleCard}>
            <input type={"checkbox"} onChange={e => e.target.checked ?
                setSelectedSchedules([...selectedSchedules, s]) : setSelectedSchedules(selectedSchedules.filter(i => i !== s))}
            />
            <p>{s.name} ({s.enabled ? "enabled" : "disabled"})</p>

            <p className={style.gridEnd}>{s.cron}</p>

            <span className={`material-symbols-outlined ${style.edit}`} onClick={() => setEdit(s)}>edit</span>

            <p>{s.prefab.name}</p>

            <p className={style.gridEnd}>{formatDate(s.start)} - {s.end ? formatDate(s.end) : "X"}</p>
        </div>)}

        <div className={style.addBallContainer}>
            <div className={style.addBall} onClick={() => setEdit(SCHEDULE_TEMPLATE_ROW())}>
                <span className="material-symbols-outlined">add</span>
            </div>
        </div>

        <Modal title={(edit.id ? "Edit" : "Create") + " Schedule"} visible={!!edit}
               setVisible={() => setEdit(false)}>
            <div className={style.modal}>

                <label>Name</label>
                <div>
                    <EditableValue value={edit.name} onChange={e => {
                        if (!edit.id) setEdit({...edit, name: e.target.value})
                        else updateSchedule({...edit, name: e.target.value})
                    }} inputProps={{required: true}}/>
                </div>

                <label>Cron</label>
                <div>
                    <CronInput cron={CRON_OPTIONS} value={edit.cron} onChange={e => {
                        if (!edit.id) setEdit({...edit, cron: e.target.value})
                        else updateSchedule({...edit, cron: e.target.value})
                    }} className={style.cronInput}/>
                </div>

                <label>Prefab</label>
                <div>
                    <Select value={edit.prefab} onChange={(e, p) => {
                        if (!edit.id) setEdit({...edit, prefab: p})
                        else updateSchedule({...edit, prefab: p})
                    }} required={true}>
                        {prefabs.map(p => <Option key={p.id} value={p}>{p.name}</Option>)}
                    </Select>
                </div>


                <label>Start</label>
                <div>
                    <EditableValue value={formatDateISO(edit.start)} onChange={e => {
                        if (!edit.id) setEdit({...edit, start: millisFromISO(e.target.value)})
                        else updateSchedule({...edit, start: millisFromISO(e.target.value)})
                    }} inputProps={{required: true, type: "date"}}/>
                </div>

                <label>End</label>
                <div>
                    <EditableValue value={formatDateISO(edit.end)} onChange={e => {
                        if (!edit.id) setEdit({...edit, end: millisFromISO(e.target.value)})
                        else updateSchedule({...edit, end: millisFromISO(e.target.value)})
                    }} inputProps={{required: false, type: "date"}}/>
                </div>

                <div>
                    {edit.id && <button onClick={() => deleteSchedule(edit) && setEdit(false)}>Delete</button>}
                </div>
                <div className={style.gridEnd}>
                    {edit.id && edit.enabled && <button onClick={() => disableSchedule(edit)}>Disable</button>}
                    {edit.id && !edit.enabled && <button onClick={() => enableSchedule(edit)}>Enable</button>}
                    <button onClick={save}>Save</button>
                </div>

            </div>
        </Modal>

    </>
}