import {createRoot} from "react-dom/client";
import {App} from "./App";

// https://git.g-7.io/joeysciamanna/parpan/-/issues/10
const consoleError = console.error;
const SUPPRESSED_WARNINGS = [
    msg => msg?.startsWith("Warning: [antd: Select] `showArrow` is deprecated"),
    (msg, a1) => msg?.startsWith("Warning: React does not recognize") && a1 === "showArrow"
];

console.error = function filterWarnings(msg, ...args) {
    if (!SUPPRESSED_WARNINGS.some(e => e(msg, ...args)))
        consoleError(msg, ...args);
};

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App/>);