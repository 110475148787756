import style from "./Modal.module.scss"

export default function ({visible, setVisible, title, children}) {
    return <>
        <div className={style.modalBG} style={{display: visible ? "grid" : "none"}}>
            <div className={style.modal}>
                <div className={style.modalHeader}>
                    <h2>{title}</h2>
                    <span className="material-symbols-outlined" onClick={e => setVisible(false)}>close</span>
                </div>
                {children}
            </div>
        </div>
    </>

}